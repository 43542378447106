import * as React from 'react';
import { Field, reduxForm } from 'redux-form';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formatUserName } from 'sportnet-utilities';
import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import { useAsyncData } from 'ssr-service';
import { withRouter } from 'react-router';
import Api from '../../api/Api';
import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import CoreApi from '../../api/CoreApi';
import FormField from '@sportnet/ui/FormField/redux-form';
import Loader from '@sportnet/ui/Loader';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import Paginator from '@sportnet/ui/Paginator';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import __ from '../../utilities/__';
import config from '../../config';
import debounce from 'lodash.debounce';
import required from '../../utilities/required';
import styled, { css } from 'styled-components';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;
const AthletesList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const AthleteItem = styled(Segment)<{ faded?: boolean }>`
  width: calc(50% - 8px);
  ${mb('s')} {
    width: calc(25% - 8px);
  }
  cursor: pointer;
  :hover {
    background: #f0f0f0;
  }
  ${({ faded }) =>
    !!faded &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      background: transparent !important;
    `}
  padding: 0;
  border: 1px solid #ddd;
  margin: 4px;
  color: #333;
  border-radius: 8px;
  font-size: 13px;
  > div > div:first-child {
    font-weight: 600;
  }
`;

const mapStateToProps = (state: RootState, props: { form: string }) => {
  return {};
};

type OwnProps = {
  competitionId?: string;
  partId?: string;
};

type Props = OwnProps &
  RouteProps<{ appSpace: string; id: string }> &
  ReduxConnectProps & { form: string } & ReturnType<typeof mapStateToProps>;

const AdminTeamForm: React.FC<Props> = ({
  form,
  params: { appSpace, id },
  dispatch,
  competitionId,
  partId,
}) => {
  const [offset, setOffset] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [query, setQuery] = React.useState('');
  const [athletes, setAthletes] = React.useState<any[]>([]);
  const [isFetchingAthletes, setIsFetchingAthletes] = React.useState(false);

  const [athleteListIsOpened, setAthleteListIsOpened] = React.useState(false);

  const [teamAthletes, setTeamAthletes] = React.useState<
    Array<{ sportnetUser: { _id: string; name: string } }>
  >([]);
  const [isFetchingTeamSquad, setIsFetchingTeamSquad] = React.useState(false);
  const [isSubmittingPlayer, setIsSubmittingPlayer] = React.useState<
    string | null
  >(null);
  const [isRemovingPlayer, setIsRemovingPlayer] = React.useState('');

  const fetchTeamSquad = async () => {
    if (competitionId && partId) {
      try {
        setIsFetchingTeamSquad(true);
        const res = await Api.adminGetTeamSquad(
          appSpace,
          config.PROJECT_NAME,
          competitionId,
          partId,
          id,
        );
        setTeamAthletes(res.squad ? res.squad.athletes || [] : []);
      } catch (e: any) {
        alert(__('Nepodarilo sa získať zoznam žiakov v triede'));
      } finally {
        setIsFetchingTeamSquad(false);
      }
    }
  };

  const addPlayerToSquad = async (data: { value: string }) => {
    try {
      setIsSubmittingPlayer(data.value);
      const res = await Api.adminAddPlayerToTeamSquad(
        appSpace,
        config.PROJECT_NAME,
        String(competitionId),
        String(partId),
        id,
        {},
        { sportnetId: data.value },
      );
      setTeamAthletes(res.squad ? res.squad.athletes || [] : []);
    } catch (e: any) {
      alert(__('Nepodarilo sa pridať žiaka do triedy.'));
    } finally {
      setIsSubmittingPlayer(null);
    }
  };

  const removePlayerFromSquad = async (sportnetId: string) => {
    try {
      setIsRemovingPlayer(sportnetId);
      const res = await Api.adminRemovePlayerFromTeamSquad(
        appSpace,
        config.PROJECT_NAME,
        String(competitionId),
        String(partId),
        id,
        sportnetId,
      );
      setTeamAthletes(res.squad ? res.squad.athletes || [] : []);
    } catch (e: any) {
      alert(__('Žiaka sa nepodarilo z triedy odstrániť'));
    } finally {
      setIsRemovingPlayer('');
    }
  };

  const fetchAthletes = React.useMemo(() => {
    setAthletes([]);
    return debounce(async () => {
      setIsFetchingAthletes(true);
      try {
        const res = await CoreApi.organizationPPOUsers(appSpace, {
          q: query,
          limit: 20,
          offset,
        });
        setAthletes(res.users || []);
        setTotal(res.total || 0);
      } catch (e: any) {
        alert(__('Nepodarilo sa získať zoznam športovcov'));
      } finally {
        setIsFetchingAthletes(false);
      }
    }, 500);
  }, [appSpace, offset, query]);

  React.useEffect(() => {
    fetchAthletes();
  }, [offset, query, fetchAthletes]);

  useAsyncData(async () => {
    fetchAthletes();
    await fetchTeamSquad();
  }, []);

  const toggleAthleteList = () => setAthleteListIsOpened(!athleteListIsOpened);

  const teamAthletesIds = teamAthletes.map((a) => a.sportnetUser._id);

  return (
    <>
      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__('Základné informácie')}
          </SegmentHeader>
        }
      >
        <Row>
          <Col xs={12}>
            <Field
              label={__('Názov triedy')}
              name="name"
              component={FormField}
              type="text"
              placeholder={__('napr. I.A - 2019/2020')}
              required
              validate={[required]}
            />
          </Col>
          {id !== 'new' && (
            <Col xs={12}>
              <Field
                label={__('Školský rok')}
                name="season.name"
                component={FormField}
                readOnly
                type="text"
                required
                validate={[required]}
              />
            </Col>
          )}
        </Row>
      </Segment>
      {id !== 'new' && (
        <>
          <Modal isOpen={athleteListIsOpened} handleClose={toggleAthleteList}>
            <ModalContent>
              <Field
                component={FormField}
                name="q"
                loading={isFetchingAthletes}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setQuery(e.currentTarget.value);
                  setOffset(0);
                }}
                label={__('Meno a priezvisko')}
                placeholder={__('Minimálne 4 znaky...')}
              />
              {isFetchingAthletes ? (
                <LoaderWrapper>
                  <Loader size="xl" />
                </LoaderWrapper>
              ) : (
                <AthletesList>
                  {athletes.map((athlete) => {
                    const included = teamAthletesIds.find(
                      (a) => a === athlete._id,
                    );
                    return (
                      <AthleteItem
                        loading={
                          !!(
                            isSubmittingPlayer &&
                            isSubmittingPlayer === athlete._id
                          )
                        }
                        key={athlete._id}
                        faded={!!included}
                      >
                        <div
                          style={{ padding: rem(8) }}
                          onClick={async () => {
                            if (!included) {
                              await addPlayerToSquad({ value: athlete._id });
                            }
                          }}
                        >
                          <div>{formatUserName(athlete)}</div>
                          <div>{athlete.birthyear}</div>
                        </div>
                      </AthleteItem>
                    );
                  })}
                </AthletesList>
              )}
              <Paginator
                total={total}
                offset={offset}
                onChangeOffset={setOffset}
                limit={20}
              />
            </ModalContent>
            <ModalActions>
              <div>&nbsp;</div>
              <div>
                <Button onClick={toggleAthleteList}>{__('Zavrieť')}</Button>
              </div>
            </ModalActions>
          </Modal>
          <Segment
            loading={isFetchingTeamSquad || !!isSubmittingPlayer}
            raised
            header={
              <SegmentHeader size="s">{__('Žiaci v triede')}</SegmentHeader>
            }
          >
            <Button primary onClick={toggleAthleteList}>
              {__('Pridať žiaka')}
            </Button>
            <br />
            <br />
            {teamAthletes.length > 0 && (
              <BasicTable
                rows={teamAthletes}
                renderRow={(i: any) => [
                  i.sportnetUser.name,
                  <Button
                    key={i.sportnetUser._id}
                    loading={isRemovingPlayer === i.sportnetUser._id}
                    onClick={() => removePlayerFromSquad(i.sportnetUser._id)}
                    danger
                  >
                    {__('Odstrániť')}
                  </Button>,
                ]}
                columns={[
                  { header: __('Meno a priezvisko') },
                  { width: 100, header: '' },
                ]}
              />
            )}
          </Segment>
        </>
      )}
    </>
  );
};

export default compose(
  reduxForm<any, OwnProps>({ enableReinitialize: true }),
  withRouter,
  connect(mapStateToProps),
)(AdminTeamForm);
