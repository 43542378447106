import __ from './__';

const required = (value: string): string | null => {
  if (value) {
    return null;
  }
  return __('Pole je povinné');
};

export default required;
