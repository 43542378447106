import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router';
import { css } from '../../theme/styled-components';
import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import Button from '@sportnet/ui/Button';
import MaxWidthBox from '@sportnet/component-library/MaxWidthBox';
import ResponsiveMenu from '@sportnet/ui/ResponsiveMenu';
import __ from '../../utilities/__';
import config from '../../config';
import styled from 'styled-components';

const Burger = styled.div`
display: flex;
align-items: center;
  ${mb('m')} {
    display: none;
  }}
`;

const MenuItem = styled.li`
  padding: ${rem(8)} ${rem(16)};
  margin: 0 ${rem(4)};
  text-align: center;
  // width: ${rem(105)};
  font-weight: 600;
  font-size: ${rem(14)};
  a {
    text-decoration: none;
    color: #333;
    :hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.color.primary};
    }
  }
`;

const FullWidthBox = styled.div<{ dark?: boolean }>`
  background: #fff;
  position: relative;
  ${({ dark }) =>
    !!dark &&
    (css as any)`
      background: linear-gradient(to bottom, #1dd1e1 0%,#0555b1 100%);
      ${MenuItem} {
        a {
          color: #fff;
        }
      }
    `}
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${rem(config.HEADER_HEIGHT)};
  padding: ${rem(8)} 0;
`;

const OffsetedLogosWrapper = styled.div`
  display: none;
  ${mb('xxl')} {
    display: flex;
  }
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  width: calc((100% - 1000px) / 2);
  > img {
    width: auto;
    height: 40px;
    margin: 0 4px;
  }
`;

const LogoWrapper = styled.div`
  max-width: 50%;
  img {
    height: 100%;
    max-width: 100%;
  }
`;
const MenuWrapper = styled.div`
  display: none;
  ${mb('m')} {
    display: inherit;
  }}
`;
const Menu = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
`;

interface Section {
  name: string;
  _id: string;
  url?: string;
  sections?: Section[];
}

const Header: React.FC<RouteComponentProps<{}, {}>> = (props) => {
  const [menuVisible, setMenuVisible] = React.useState(false);

  const renderLink = (renderProps: {
    section: Section;
    linkProps: {
      className: string;
      onClick: () => void;
      children: React.ReactNode;
      url: string;
    };
    level: number;
  }) => {
    const { linkProps, section } = renderProps;
    if (!section.url?.startsWith('/')) {
      return (
        <a
          className="responsive-menu-link"
          href={section.url || ''}
          key={section._id}
        >
          {linkProps.children}
        </a>
      );
    }
    return (
      <Link to={section.url || ''} {...linkProps}>
        {linkProps.children}
      </Link>
    );
  };

  const menuItems: Section[] = [
    { name: __('Zapojené centrá'), _id: 'schools', url: '/centra' },
    {
      name: __('O projekte'),
      _id: 'project',
      url: 'https://futbalsfz.sk/mladez-a-rozvoj/zensky-futbal/uefa-disney-playmakers/',
    },
    {
      name: __('Články'),
      _id: 'articles',
      url: 'https://futbalsfz.sk/mladez-a-rozvoj/zensky-futbal/uefa-disney-playmakers/spravy/',
    },
    {
      name: __('Videá'),
      _id: 'videos',
      url: 'https://futbalsfz.sk/mladez-a-rozvoj/zensky-futbal/uefa-disney-playmakers/video/',
    },
    {
      name: __('Vzdelávanie'),
      _id: 'training',
      url: 'https://futbalsfz.sk/treneri/skolenia/sfz-grassroots-leader/',
    },
    {
      name: __('Kontakty'),
      _id: 'contact',
      url: 'https://futbalsfz.sk/mladez-a-rozvoj/zensky-futbal/uefa-disney-playmakers/kontakt/',
    },
    { name: __('Správa centra'), _id: 'admin', url: '/admin' },
  ];

  const isDark = props.location.pathname === '/';

  return (
    <FullWidthBox dark={isDark}>
      <OffsetedLogosWrapper>
        <img alt="FA" src="/FA.svg" />
        <img alt="uefa" src="/uefa.png" />
        <img
          alt="sfz"
          src="https://csm.sportnet.online/images/150x90xresize/csm/d/a/j/dajmespolugol/97/5a/975a374f-f3ab-49b9-abcd-d5a7630f545d.png"
        />
      </OffsetedLogosWrapper>
      <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
        <Wrapper>
          <LogoWrapper>
            <Link to="/">
              <img
                alt="logo"
                src={isDark ? '/disney.svg' : '/disney-dark.svg'}
              />
            </Link>
          </LogoWrapper>
          <MenuWrapper>
            <Menu>
              {menuItems.map((i) => {
                if (i.url?.startsWith('/')) {
                  return (
                    <MenuItem key={i._id}>
                      <Link to={i.url || ''} key={i._id}>
                        {i.name}
                      </Link>
                    </MenuItem>
                  );
                }
                return (
                  <MenuItem key={i._id}>
                    <a href={i.url || ''} key={i._id}>
                      {i.name}
                    </a>
                  </MenuItem>
                );
              })}
            </Menu>
          </MenuWrapper>
          <Burger>
            <Button
              icon="burger"
              primary
              onClick={() => {
                setMenuVisible(true);
              }}
            />
          </Burger>
          <ResponsiveMenu
            siteUrl={'Playmakers'}
            sections={menuItems}
            visible={menuVisible}
            onClose={() => setMenuVisible(false)}
            renderLink={renderLink}
          />
        </Wrapper>
      </MaxWidthBox>
    </FullWidthBox>
  );
};

export default withRouter(Header) as any;
