import {
  Field,
  change,
  formValueSelector,
  getFormMeta,
  getFormSyncErrors,
  reduxForm,
  submit,
} from 'redux-form';
import { RootState } from '../../configureStore';
import { connect } from 'react-redux';
import { email, phone, required } from './validate';
import { getProp } from 'sportnet-utilities';
import { useAsyncData } from 'ssr-service';
import BasicTable from '@sportnet/ui/BasicTable';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import CompetentPeopleForm from './competentPeopleForm';
import CoreApi from '../../api/CoreApi';
import FormField from '@sportnet/ui/FormField/redux-form';
import Header from '@sportnet/ui/Header';
import Input from '@sportnet/ui/Input/index';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import React from 'react';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import __ from '../../utilities/__';
import isValidIBAN from '../../utilities/isValidIBAN';
import styled from 'styled-components';

const Wrapper = styled.div`
  * {
    letter-spacing: 0;
  }
`;

const COMPETENT_PEOPLE_FORM_NAME = 'COMPETENT_PEOPLE_FORM';
export const formName = 'DEMAND';

export const PERSON_POSITION_TYPES = [
  {
    label: __('Tréner'),
    value: 'coach',
  },
  {
    label: __('Triedny učiteľ'),
    value: 'class_teacher',
  },
  {
    label: __('Učiteľ'),
    value: 'teacher',
  },
];

const childrenCountValidation = (value: string | number): string | null => {
  if (
    value &&
    Number.isInteger(Number(value)) &&
    Number(value) > 0 &&
    Number(value) <= 100
  ) {
    return null;
  }
  return __('Zadajte číslo medzi 1-100');
};

type CodelistItem = {
  value?: string;
  label?: string;
};

const RequestForm: React.FC<any> = ({
  isFetching,
  isBusinessInfoFetching,
  isSportExpertFetching,
  onGetBusinessInfo,
  targetGroups,
  initialValues = {},
  bankAccounts,
  dispatch,
  addresses,
  address,
  users = [],
  competentPeopleFormErrors,
  competentPeopleFormMeta,
  appSpace,
}) => {
  const [modalOpened, setModalOpened] = React.useState(false);
  const [invoiceAddressModalOpened, setInvoiceAddressModalOpened] =
    React.useState(false);
  const [postalAddressModalOpened, setPostalAddressModalOpened] =
    React.useState(false);

  const [isFetchingCodelists, setIsFetchingCodelists] = React.useState(false);

  const [country, setCountry] = React.useState<CodelistItem[]>([]);
  const [legalForm, setLegalForm] = React.useState<CodelistItem[]>([]);
  const [licenseType, setLicenseType] = React.useState<CodelistItem[]>([]);
  const [profileType, setProfileType] = React.useState<CodelistItem[]>([]);

  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  useAsyncData(async () => {
    try {
      setIsFetchingCodelists(true);
      const countryCodelist = await CoreApi.getCodelist('country');
      const legalFormCodelist = await CoreApi.getCodelist('legal-form');
      const licenseTypeCodelist = await CoreApi.getCodelist('license-type');
      const profileTypeCodelist = await CoreApi.getCodelist(
        'organization-profile-type',
      );
      setCountry(countryCodelist.codelist || []);
      setLegalForm(legalFormCodelist.codelist || []);
      setLicenseType(licenseTypeCodelist.codelist || []);
      setProfileType(profileTypeCodelist.codelist || []);
    } catch (e: any) {
      //
    } finally {
      setIsFetchingCodelists(false);
    }
  }, []);

  const allowedProfileTypes = [
    'materska-skola',
    'zakladna-skola',
    'zakladna-skola-s-materskou-skolou',
  ];
  const profileTypes = profileType.filter((i: any) =>
    allowedProfileTypes.includes(i.value),
  );

  return (
    <Wrapper>
      {!appSpace ? (
        <React.Fragment>
          <Segment
            loading={isBusinessInfoFetching}
            raised
            header={
              <SegmentHeader size="s" withSeparator>
                {__('Organizácia')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12} m={6}>
                <Field
                  name="organization.full_name"
                  component={FormField}
                  type="text"
                  label={__('Názov spoločnosti')}
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, [
                    'organization',
                    'full_name',
                  ])}
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="organization.business_id"
                  component={FormField}
                  label={__('IČO')}
                  type="text"
                  // validate={[required, minLength6, maxLength8]}
                  validate={[required]}
                  required
                  isFetching={isBusinessInfoFetching}
                  onAction={onGetBusinessInfo}
                  onBlur={onGetBusinessInfo}
                >
                  <input />
                  <Input.Button
                    primary
                    icon="search"
                    onClick={onGetBusinessInfo}
                  />
                </Field>
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="organization.vat_nr"
                  component={FormField}
                  label={__('DIČ/IČDPH')}
                  type="text"
                  disabled={getProp(initialValues, ['organization', 'vat_nr'])}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="organization.name"
                  component={FormField}
                  label={__('Skrátený názov')}
                  type="text"
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, ['organization', 'name'])}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="organization.legal_form"
                  component={FormField}
                  type="theselect"
                  label={__('Právna forma')}
                  validate={[required]}
                  required
                  format={(i: any) => {
                    if (typeof i === 'string') {
                      const item = legalForm.find((l) => l.value === i);
                      return item || { value: i, label: i };
                    }
                    return i;
                  }}
                  options={legalForm.map((i: any) => ({
                    label: i.label,
                    value: i.value,
                  }))}
                  disabled={getProp(initialValues, [
                    'organization',
                    'legal_form',
                  ])}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="bank_account.IBAN"
                  component={FormField}
                  type="text"
                  validate={[required, isValidIBAN]}
                  required
                  label={__('Bankové spojenie (IBAN)')}
                  placeholder={__(
                    'Zadajte účet, na ktorý budú vyplatené finančné prostriedky pre účel projektu',
                  )}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="organization.contact_person"
                  component={FormField}
                  type="text"
                  validate={[required]}
                  required
                  label={__('Štatutárny zástupca')}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="organization.phone"
                  component={FormField}
                  type="text"
                  validate={[required, phone]}
                  required
                  label={__('Telefónne číslo')}
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            loading={isBusinessInfoFetching}
            raised
            header={
              <SegmentHeader
                size="s"
                withSeparator
                subtitle={__(
                  'Na túto mailovú adresu budú odoslané zmluvy a ďaľšie informácie',
                )}
              >
                {__('Email')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12} s={6}>
                <Field
                  name="organization.email"
                  component={FormField}
                  label={__('E-mailová adresa')}
                  type="email"
                  validate={[required, email]}
                  required
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            raised
            header={
              <SegmentHeader size="s" withSeparator>
                {__('Fakturačná adresa')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12} m={6}>
                <Field
                  name="address.INVOICE.street"
                  component={FormField}
                  type="text"
                  label={__('Ulica')}
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, [
                    'address',
                    'INVOICE',
                    'street',
                  ])}
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="address.INVOICE.number"
                  component={FormField}
                  type="text"
                  label={__('Číslo')}
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, [
                    'address',
                    'INVOICE',
                    'number',
                  ])}
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="address.INVOICE.zip"
                  component={FormField}
                  label={__('PSČ')}
                  type="text"
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, [
                    'address',
                    'INVOICE',
                    'zip',
                  ])}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="address.INVOICE.city"
                  component={FormField}
                  label={__('Mesto')}
                  type="text"
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, [
                    'address',
                    'INVOICE',
                    'city',
                  ])}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="address.INVOICE.country"
                  component={FormField}
                  type="theselect"
                  loading={isFetchingCodelists}
                  validate={[required]}
                  required
                  label={__('Štát')}
                  disabled={getProp(initialValues, [
                    'address',
                    'INVOICE',
                    'country',
                  ])}
                  format={(i: any) => {
                    if (typeof i === 'string') {
                      const item = country.find((c) => c.value === i);
                      return item || i;
                    }
                    return i;
                  }}
                  options={country.map((i) => ({
                    label: i.label,
                    value: i.value,
                  }))}
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            raised
            header={
              <SegmentHeader size="s" withSeparator>
                {__('Škola')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12} s={6}>
                <Field
                  name="profile.name"
                  component={FormField}
                  label={__('Názov zariadenia')}
                  type="text"
                  validate={[required]}
                  required
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="profile.type"
                  component={FormField}
                  validate={[required]}
                  required
                  label={__('Typ zariadenia')}
                  type="theselect"
                  loading={isFetchingCodelists}
                  options={profileTypes.map((i) => ({
                    label: i.label,
                    value: i.value,
                  }))}
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            raised
            header={
              <SegmentHeader
                size="s"
                withSeparator
                subtitle={__(
                  'Adresa slúži na zasielanie materiálneho zabezpečenia',
                )}
              >
                {__('Korešpondenčná adresa')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12} m={6}>
                <Field
                  name="address.POSTAL.street"
                  component={FormField}
                  type="text"
                  label={__('Ulica')}
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, [
                    'address',
                    'POSTAL',
                    'street',
                  ])}
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="address.POSTAL.number"
                  component={FormField}
                  type="text"
                  label={__('Číslo')}
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, [
                    'address',
                    'POSTAL',
                    'number',
                  ])}
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="address.POSTAL.zip"
                  component={FormField}
                  label={__('PSČ')}
                  type="text"
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, [
                    'address',
                    'POSTAL',
                    'zip',
                  ])}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="address.POSTAL.city"
                  component={FormField}
                  label={__('Mesto')}
                  type="text"
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, [
                    'address',
                    'POSTAL',
                    'city',
                  ])}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="address.POSTAL.country"
                  component={FormField}
                  type="theselect"
                  validate={[required]}
                  required
                  label={__('Štát')}
                  disabled={getProp(initialValues, [
                    'address',
                    'POSTAL',
                    'country',
                  ])}
                  options={country.map((i) => ({
                    label: i.label,
                    value: i.value,
                  }))}
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            raised
            header={
              <SegmentHeader
                size="s"
                withSeparator
                subtitle={__(
                  'Uvádzajte údaje riaditeľa prípadne zástupcu školy',
                )}
              >
                {__('Ďalšia kontaktná osoba')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12} m={6}>
                <Field
                  name="contact_person_2.display_name"
                  component={FormField}
                  type="text"
                  validate={[required]}
                  label={__('Meno a priezvisko')}
                  required
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="contact_person_2.phone"
                  component={FormField}
                  type="text"
                  validate={[required, phone]}
                  label={__('Telefónne číslo')}
                  required
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="contact_person_2.email"
                  component={FormField}
                  type="email"
                  validate={[required, email]}
                  label={__('E-mail')}
                  required
                />
              </Col>
            </Row>
          </Segment>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Segment
            raised
            header={
              <SegmentHeader size="s" withSeparator>
                {__('Organizácia')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12} m={6}>
                <Field
                  name="organization.full_name"
                  component={FormField}
                  type="text"
                  label={__('Názov spoločnosti')}
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, [
                    'organization',
                    'full_name',
                  ])}
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="organization.business_id"
                  component={FormField}
                  label={__('IČO')}
                  type="text"
                  disabled
                  // validate={[required, minLength6, maxLength8]}
                  validate={[required]}
                  required
                  isFetching={isBusinessInfoFetching}
                  onAction={onGetBusinessInfo}
                  onBlur={onGetBusinessInfo}
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="organization.vat_nr"
                  component={FormField}
                  label={__('DIČ/IČDPH')}
                  type="text"
                  disabled={getProp(initialValues, ['organization', 'vat_nr'])}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="organization.name"
                  component={FormField}
                  label={__('Skrátený názov')}
                  type="text"
                  validate={[required]}
                  required
                  disabled={getProp(initialValues, ['organization', 'name'])}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="organization.legal_form"
                  component={FormField}
                  type="theselect"
                  label={__('Právna forma')}
                  loading={isFetchingCodelists}
                  validate={[required]}
                  required
                  format={(i: any) => {
                    if (typeof i === 'string') {
                      const item = legalForm.find((l) => l.value === i);
                      return item || { value: i, label: i };
                    }
                    return i;
                  }}
                  options={legalForm.map((i) => ({
                    label: i.label,
                    value: i.value,
                  }))}
                  disabled={getProp(initialValues, [
                    'organization',
                    'legal_form',
                  ])}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  component={FormField}
                  label={__('Bankové spojenie (IBAN)')}
                  name="bank_account.IBAN"
                  placeholder={__(
                    'Vyberte účet, na ktorý budú vyplatené finančné prostriedky pre účel projektu',
                  )}
                  required
                  type="theselect"
                  options={bankAccounts.map((i: any) => ({
                    label: i.IBAN,
                    value: i._id,
                  }))}
                  parse={(i: any) => (i ? i.value : i)}
                  format={(i: any) => {
                    if (!i) {
                      return i;
                    }
                    const bankAccount = bankAccounts.find(
                      (ba: any) => ba._id === i,
                    );

                    if (bankAccount) {
                      return {
                        label: bankAccount.IBAN,
                        value: bankAccount._id,
                      };
                    }
                  }}
                  validate={[required]}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="organization.contact_person"
                  component={FormField}
                  type="text"
                  validate={[required]}
                  required
                  label={__('Štatutárny zástupca')}
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="organization.phone"
                  component={FormField}
                  type="text"
                  validate={[required, phone]}
                  required
                  label={__('Telefónne číslo')}
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            loading={isBusinessInfoFetching}
            raised
            header={
              <SegmentHeader
                size="s"
                withSeparator
                subtitle={__(
                  'Na túto mailovú adresu budú odoslané zmluvy a ďaľšie informácie',
                )}
              >
                {__('Email')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12} s={6}>
                <Field
                  name="organization.email"
                  component={FormField}
                  label={__('E-mailová adresa')}
                  type="email"
                  validate={[required, email]}
                  required
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            raised
            header={
              <SegmentHeader size="s" withSeparator>
                {__('Fakturačná adresa')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12}>
                <Field
                  component={FormField}
                  label={__('Fakturačná adresa')}
                  name="address.INVOICE_DRAFT"
                  placeholder={__('Vyberte fakturačnú adresu')}
                  required
                  type="theselect"
                  options={[
                    { label: __('Nová adresa'), value: 'new' },
                    ...addresses.map((i: any) => ({
                      label: `${i.street} ${i.number} ${i.city} ${i.zip} ${i.country}`,
                      value: i._id,
                    })),
                  ]}
                  onChange={(i: any) => {
                    if (i.value) {
                      const item = addresses.find(
                        (a: any) => a._id === i.value,
                      );
                      setInvoiceAddressModalOpened(true);
                      dispatch(change(formName, 'address.INVOICE', item || {}));
                    }
                  }}
                  parse={(i: any) => {
                    if (i && i.value) {
                      const item = addresses.find(
                        (a: any) => a._id === i.value,
                      );
                      if (item) {
                        return item;
                      }
                      return {
                        street: '',
                        number: '',
                        city: '',
                        zip: '',
                        country: '',
                      };
                    }
                  }}
                  format={(i: any) => {
                    if (!i) {
                      return i;
                    }
                    return {
                      label: `${i.street} ${i.number} ${i.city} ${i.zip} ${
                        i.country && i.country.value
                          ? i.country.value
                          : i.country
                      }`,
                      value: i._id,
                    };
                  }}
                  validate={[required]}
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            raised
            header={
              <SegmentHeader size="s" withSeparator>
                {__('Škola')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12} s={6}>
                <Field
                  name="profile.name"
                  component={FormField}
                  label={__('Názov zariadenia')}
                  readOnly
                  type="text"
                  validate={[required]}
                  required
                />
              </Col>
              <Col xs={12} s={6}>
                <Field
                  name="profile.type"
                  component={FormField}
                  validate={[required]}
                  required
                  label={__('Typ zariadenia')}
                  type="theselect"
                  options={profileTypes.map((i) => ({
                    label: i.label,
                    value: i.value,
                  }))}
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            raised
            header={
              <SegmentHeader
                size="s"
                withSeparator
                subtitle={__(
                  'Adresa slúži na zasielanie materiálneho zabezpečenia',
                )}
              >
                {__('Korešpondenčná adresa')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12}>
                <Field
                  component={FormField}
                  label={__('Korešpondečná adresa')}
                  name="address.POSTAL_DRAFT"
                  placeholder={__('Vyberte korešpondečnú adresu')}
                  required
                  type="theselect"
                  options={[
                    { label: __('Nová adresa'), value: 'new' },
                    ...addresses.map((i: any) => ({
                      label: `${i.street} ${i.number} ${i.city} ${i.zip} ${i.country}`,
                      value: i._id,
                    })),
                  ]}
                  onChange={(i: any) => {
                    if (i.value) {
                      const item = addresses.find(
                        (a: any) => a._id === i.value,
                      );
                      setPostalAddressModalOpened(true);
                      dispatch(change(formName, 'address.POSTAL', item || {}));
                    }
                  }}
                  parse={(i: any) => {
                    if (i && i.value) {
                      const item = addresses.find(
                        (a: any) => a._id === i.value,
                      );
                      if (item) {
                        return item;
                      }
                      return {
                        street: '',
                        number: '',
                        city: '',
                        zip: '',
                        country: '',
                      };
                    }
                  }}
                  format={(i: any) => {
                    if (!i) {
                      return i;
                    }
                    return {
                      label: `${i.street} ${i.number} ${i.city} ${i.zip} ${
                        i.country && i.country.value
                          ? i.country.value
                          : i.country
                      }`,
                      value: i._id,
                    };
                  }}
                  validate={[required]}
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            raised
            header={
              <SegmentHeader
                size="s"
                withSeparator
                subtitle={__(
                  'Uvádzajte údaje riaditeľa prípadne zástupcu školy',
                )}
              >
                {__('Ďalšia kontaktná osoba')}
              </SegmentHeader>
            }
          >
            <Row>
              <Col xs={12} m={6}>
                <Field
                  name="contact_person_2.display_name"
                  component={FormField}
                  type="text"
                  validate={[required]}
                  label={__('Meno a priezvisko')}
                  required
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="contact_person_2.phone"
                  component={FormField}
                  type="text"
                  validate={[required, phone]}
                  label={__('Telefónne číslo')}
                  required
                />
              </Col>
              <Col xs={12} s={6} m={3}>
                <Field
                  name="contact_person_2.email"
                  component={FormField}
                  type="email"
                  validate={[required, email]}
                  label={__('E-mail')}
                  required
                />
              </Col>
            </Row>
          </Segment>
        </React.Fragment>
      )}
      <React.Fragment>
        <Segment
          raised
          header={
            <SegmentHeader
              size="s"
              withSeparator
              subtitle={__(
                'Tréneri zodpovední za bezpečnosť detí počas realizácie pohybovo-športových aktivít so zameraním na futbal',
              )}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>{__('Oprávnené osoby')}</span>
                <Button icon="plus" primary onClick={toggleModal} />
              </div>
            </SegmentHeader>
          }
        >
          {users.length ? (
            <BasicTable
              rowKey="sportnetId"
              rows={users}
              columns={[
                { header: __('Meno a priezvisko') },
                { header: __('Pozícia'), width: 200 },
                { header: __('Číslo licencie'), width: 200 },
                { header: '', width: 50 },
              ]}
              renderRow={(i, idx) => [
                i.name,
                i.license_type.label,
                i.license_id,
                <Button
                  key={i.sportnetId}
                  danger
                  icon="close"
                  onClick={() => {
                    const currentUsers = users.reduce(
                      (acc: any, u: any, arrIdx: any) => {
                        if (arrIdx === idx) {
                          return acc;
                        }
                        return [...acc, u];
                      },
                      [],
                    );
                    dispatch(change(formName, 'users', currentUsers));
                  }}
                />,
              ]}
            />
          ) : (
            <div />
          )}
        </Segment>
        {modalOpened && (
          <Modal isOpen={modalOpened} handleClose={toggleModal} centered>
            <ModalContent>
              <Wrapper>
                <Header size="xs" withSeparator>
                  {__('Nová oprávnená osoba')}
                </Header>
                <CompetentPeopleForm
                  form={COMPETENT_PEOPLE_FORM_NAME}
                  positions={PERSON_POSITION_TYPES}
                  errors={competentPeopleFormErrors}
                  licenseType={licenseType}
                  loading={isFetchingCodelists}
                  meta={competentPeopleFormMeta}
                  initialValues={{
                    user: {
                      license_type: { label: 'Tréner', value: 'Tréner' },
                    },
                  }}
                  onSubmit={(values: any) => {
                    const currentUsers = users;
                    currentUsers.push(values.user);
                    dispatch(change(formName, 'users', currentUsers));
                    toggleModal();
                  }}
                />
              </Wrapper>
            </ModalContent>
            <ModalActions>
              <div>&nbsp;</div>
              <div>
                <Button onClick={toggleModal}>{__('Zavrieť')}</Button>
                &nbsp;
                <Button
                  primary
                  onClick={() => {
                    dispatch(submit(COMPETENT_PEOPLE_FORM_NAME));
                  }}
                >
                  {__('Pridať')}
                </Button>
              </div>
            </ModalActions>
          </Modal>
        )}
      </React.Fragment>
      <Segment
        raised
        header={
          <SegmentHeader size="s" withSeparator>
            {__('Cieľové skupiny')}
          </SegmentHeader>
        }
      >
        <Row>
          <Col xs={12}>
            <Field
              label={__('Cieľová skupina')}
              type="theselectsimple"
              component={FormField}
              required
              validate={[required]}
              name="targetGroups"
              options={[
                { label: __('Žiaci materskej školy'), value: '0' },
                { label: __('Žiaci základnej školy'), value: '1' },
              ]}
            />
          </Col>
        </Row>
      </Segment>
      {typeof targetGroups !== 'undefined' && (
        <Segment
          key={targetGroups}
          raised
          header={
            <SegmentHeader size="s" withSeparator>
              {__('Cieľová skupina')} {Number(targetGroups) + 1}
            </SegmentHeader>
          }
        >
          <Segment noBottomGutter>
            <Row>
              <Col xs={12}>
                <Field
                  component={FormField}
                  type="number"
                  validate={[childrenCountValidation]}
                  required
                  name={`targetGroup.${targetGroups}.children_count`}
                  label={__('Predpokladaný počet zapojených detí')}
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            noBottomGutter
            header={
              <SegmentHeader size="xs">{__('Jesenná časť')}</SegmentHeader>
            }
          >
            <Row>
              <Col xs={12}>
                <Field
                  component={FormField}
                  type="checkbox"
                  name={`targetGroup.${targetGroups}.months['october']`}
                  label={__('Október')}
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={FormField}
                  type="checkbox"
                  name={`targetGroup.${targetGroups}.months['november']`}
                  label={__('November')}
                />
              </Col>
            </Row>
          </Segment>
          <Segment
            noBottomGutter
            header={<SegmentHeader size="xs">{__('Jarná časť')}</SegmentHeader>}
          >
            <Row>
              <Col xs={12}>
                <Field
                  component={FormField}
                  type="checkbox"
                  name={`targetGroup.${targetGroups}.months['february']`}
                  label={__('Február')}
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={FormField}
                  type="checkbox"
                  name={`targetGroup.${targetGroups}.months['march']`}
                  label={__('Marec')}
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={FormField}
                  type="checkbox"
                  name={`targetGroup.${targetGroups}.months['april']`}
                  label={__('Apríl')}
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={FormField}
                  type="checkbox"
                  name={`targetGroup.${targetGroups}.months['may']`}
                  label={__('Máj')}
                />
              </Col>
            </Row>
          </Segment>
          {/* {appSpace ? (
            <Segment noBottomGutter>
              <Field
                component={FormField}
                label={`${__(
                  'Bankový účet na vyplatenie finančných prostriedkov za cieľovú skupinu',
                )} ${targetGroups + 1}`}
                name={`targetGroup.${targetGroups}.iban`}
                required
                type="theselect"
                placeholder={__(
                  'Vyberte účet, na ktorý budú vyplatené finančné prostriedky za cieľovú skupinu',
                )}
                options={bankAccounts.map((i: any) => ({
                  label: i.IBAN,
                  value: i._id,
                }))}
                parse={(i: any) => (i ? i.value : i)}
                format={(i: any) => {
                  if (!i) {
                    return i;
                  }
                  const bankAccount = bankAccounts.find(
                    (ba: any) => ba._id === i,
                  );

                  if (bankAccount) {
                    return {
                      label: bankAccount.IBAN,
                      value: bankAccount._id,
                    };
                  }
                }}
                validate={[required]}
              />
            </Segment>
          ) : (
            <Segment noBottomGutter>
              <Field
                component={FormField}
                label={`${__(
                  'Bankový účet na vyplatenie finančných prostriedkov za cieľovú skupinu',
                )} ${targetGroups + 1}`}
                name={`targetGroup.${targetGroups}.iban`}
                required
                validate={[required, isValidIBAN]}
              />
            </Segment>
          )} */}
        </Segment>
      )}
      <Button
        loading={isFetching}
        primary
        type="button"
        onClick={() => {
          dispatch(submit(formName));
        }}
        block
        disabled={
          isFetching ||
          isBusinessInfoFetching ||
          isSportExpertFetching[1] ||
          isSportExpertFetching[2]
        }
      >
        {__('Odoslať')}
      </Button>
      <Modal
        isOpen={invoiceAddressModalOpened}
        handleClose={() => {
          setInvoiceAddressModalOpened(false);
        }}
      >
        <ModalContent>
          <Row>
            <Col xs={12} m={6}>
              <Field
                name="address.INVOICE.street"
                component={FormField}
                type="text"
                label={__('Ulica')}
                validate={[required]}
                required
              />
            </Col>
            <Col xs={12} s={6} m={3}>
              <Field
                name="address.INVOICE.number"
                component={FormField}
                type="text"
                label={__('Číslo')}
                validate={[required]}
                required
              />
            </Col>
            <Col xs={12} s={6} m={3}>
              <Field
                name="address.INVOICE.zip"
                component={FormField}
                label={__('PSČ')}
                type="text"
                validate={[required]}
                required
              />
            </Col>
            <Col xs={12} s={6}>
              <Field
                name="address.INVOICE.city"
                component={FormField}
                label={__('Mesto')}
                type="text"
                validate={[required]}
                required
              />
            </Col>
            <Col xs={12} s={6}>
              <Field
                name="address.INVOICE.country"
                component={FormField}
                type="theselect"
                validate={[required]}
                required
                label={__('Štát')}
                format={(i: any) => {
                  if (typeof i === 'string') {
                    const item = country.find((c) => c.value === i);
                    return item || i;
                  }
                  return i;
                }}
                options={country.map((i) => ({
                  label: i.label,
                  value: i.value,
                }))}
              />
            </Col>
          </Row>
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button
              onClick={() => {
                setInvoiceAddressModalOpened(false);
                dispatch(change(formName, 'address.INVOICE_DRAFT', null));
                dispatch(change(formName, 'address.INVOICE', null));
              }}
            >
              {__('Zrušiť')}
            </Button>
            &nbsp;
            <Button
              primary
              onClick={() => {
                setInvoiceAddressModalOpened(false);
                dispatch(
                  change(formName, 'address.INVOICE_DRAFT', address.INVOICE),
                );
              }}
            >
              {__('Potvrdiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
      <Modal
        isOpen={postalAddressModalOpened}
        handleClose={() => {
          setPostalAddressModalOpened(false);
        }}
      >
        <ModalContent>
          <Row>
            <Col xs={12} m={6}>
              <Field
                name="address.POSTAL.street"
                component={FormField}
                type="text"
                label={__('Ulica')}
                validate={[required]}
                required
              />
            </Col>
            <Col xs={12} s={6} m={3}>
              <Field
                name="address.POSTAL.number"
                component={FormField}
                type="text"
                label={__('Číslo')}
                validate={[required]}
                required
              />
            </Col>
            <Col xs={12} s={6} m={3}>
              <Field
                name="address.POSTAL.zip"
                component={FormField}
                label={__('PSČ')}
                type="text"
                validate={[required]}
                required
              />
            </Col>
            <Col xs={12} s={6}>
              <Field
                name="address.POSTAL.city"
                component={FormField}
                label={__('Mesto')}
                type="text"
                validate={[required]}
                required
              />
            </Col>
            <Col xs={12} s={6}>
              <Field
                name="address.POSTAL.country"
                component={FormField}
                type="theselect"
                validate={[required]}
                required
                label={__('Štát')}
                format={(i: any) => {
                  if (typeof i === 'string') {
                    const item = country.find((c) => c.value === i);
                    return item || i;
                  }
                  return i;
                }}
                options={country.map((i) => ({
                  label: i.label,
                  value: i.value,
                }))}
              />
            </Col>
          </Row>
        </ModalContent>
        <ModalActions>
          <div>&nbsp;</div>
          <div>
            <Button
              onClick={() => {
                setPostalAddressModalOpened(false);
                dispatch(change(formName, 'address.POSTAL_DRAFT', null));
                dispatch(change(formName, 'address.POSTAL', null));
              }}
            >
              {__('Zrušiť')}
            </Button>
            &nbsp;
            <Button
              primary
              onClick={() => {
                setPostalAddressModalOpened(false);
                dispatch(
                  change(formName, 'address.POSTAL_DRAFT', address.POSTAL),
                );
              }}
            >
              {__('Potvrdiť')}
            </Button>
          </div>
        </ModalActions>
      </Modal>
    </Wrapper>
  );
};

const mapStateToProps = (state: RootState) => {
  const selector = formValueSelector(formName);
  return {
    appSpace: selector(state, 'appSpace'),
    targetGroups: selector(state, 'targetGroups'),
    users: selector(state, 'users'),
    address: selector(state, 'address'),
    competentPeopleFormErrors: getFormSyncErrors(COMPETENT_PEOPLE_FORM_NAME)(
      state,
    ),
    competentPeopleFormMeta: getFormMeta(COMPETENT_PEOPLE_FORM_NAME)(state),
  };
};

const reduxFormConnected = reduxForm({
  form: formName,
  enableReinitialize: true,
  // validate: (values: any) => {
  //   const targetGroups = getProp(values, ['targetGroups'], []).filter(
  //     (i: any) => i,
  //   );
  //   const errors: any = {};
  //   if (!targetGroups.length) {
  //     errors.targetGroups = [];
  //     errors.targetGroups[1] = __('Vyberte aspoň jednu cieľovú skupinu');
  //   }
  //   return errors;
  // },
})(RequestForm);

export default connect(mapStateToProps)(reduxFormConnected as any) as any;
