import { parseToRgb } from 'polished';

const getColor = function (color = '', fallbackColor = '#fff') {
  try {
    parseToRgb(color);
    return color;
  } catch (e: any) {
    return fallbackColor;
  }
};

export default getColor;
