import * as React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router';
import { rem } from 'polished';
import EntryAnimation from '../../components/EntryAnimation';
import Page from '../../containers/Page';
import Segment from '@sportnet/ui/Segment';
import config from '../../config';
import styled from 'styled-components';

const Wrapper = styled.div`
  // background: ${({ theme }) => theme.color.primary};
  background: #eee;
  min-height: calc(100vh - ${rem(config.HEADER_HEIGHT)});
`;

const Content = styled.div`
  position: relative;
  margin: auto;
  padding: ${rem(34)} ${rem(8)} ${rem(1)} ${rem(8)};
  max-width: ${rem(config.CONTENT_MAX_WIDTH)};
`;

const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-top: ${rem(30)};
`;

const HeaderContainer = styled.div`
  margin: 0 8px;
  max-width: calc(100% - 16px);
  width: calc(${rem(config.CONTENT_MAX_WIDTH)} - 16px);
  left: calc(50% - 8px);
  position: absolute;
  background: #eee;
  border-radius: ${rem(4)};
  top: 50%;
  transform: translate(-50%, -50%);
  h1 {
    margin: 0;
    font-weight: 200;
    letter-spacing: -1px;
    font-size: 26px;
    color: #333;
    padding-left: ${rem(8)};
  }
`;

type OwnProps = {
  title: string;
  metaTitle?: string;
  children?: React.ReactNode;
};

const PublicPage: React.FC<OwnProps & RouteComponentProps<{}, {}>> = ({
  title,
  metaTitle,
  children,
  location,
}) => {
  return (
    <EntryAnimation key={title}>
      <Helmet>
        <title>{metaTitle || title}</title>
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/${location.pathname}`}
        />
        <meta property="og:title" content={metaTitle || title} />
        <meta property="og:type" content="section" />
      </Helmet>
      <Page>
        <HeaderWrapper>
          <HeaderContainer>
            <Segment raised noBottomGutter>
              <h1>{title}</h1>
            </Segment>
          </HeaderContainer>
        </HeaderWrapper>
        <Wrapper>
          <Content>{children}</Content>
        </Wrapper>
      </Page>
    </EntryAnimation>
  );
};

export default withRouter<OwnProps>(PublicPage);
