import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { ISportGround } from './types';
import { ReduxConnectProps } from '../../configureStore';
import { RouteProps } from '../../library/App';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useAsyncData } from 'ssr-service';
import { withRouter } from 'react-router';
import AdminSportGroundForm from './form';
import Api from '../../api/Api';
import Button from '@sportnet/ui/Button';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import __ from '../../utilities/__';
import config from '../../config';

const FORM_NAME = 'SPORTGROUND_FORM';

type Props = RouteProps<{ appSpace: string; id: string }> & ReduxConnectProps;

const AdminSportGround: React.FC<Props> = ({
  params: { appSpace, id },
  router,
  dispatch,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<null | ISportGround>(
    null,
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  useAsyncData(async () => {
    if (id !== 'new') {
      setIsFetching(true);
      try {
        const res = await Api.adminGetSportGroundById(
          appSpace,
          config.PROJECT_NAME,
          id,
        );
        setInitialValues(res);
      } catch (e: any) {
        alert(__('Nepodarilo sa získať podrobnosti aktivity'));
      } finally {
        setIsFetching(false);
      }
    }
  }, [appSpace, id]);

  const onSubmit = async (data: {
    address: {
      country: string;
      street: string;
      city: string;
      number: string;
      zip: string;
      geo: { lat: number; lng: number };
    };
  }) => {
    const submitData = {
      ...data.address,
    };
    try {
      setIsSubmitting(true);
      if (id !== 'new') {
        // update
        // await Api.adminUpdateEvent(appSpace, id, undefined, submitData);
      } else {
        await Api.adminCreateSportGround(
          appSpace,
          config.PROJECT_NAME,
          undefined,
          submitData,
        );
        router.push(`/admin/appSpace/${appSpace}/sportgrounds`);
      }
    } catch (e: any) {
      alert(__('Zmeny sa nepodarilo uložiť'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ScrollLayout
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              onClick={() => {
                dispatch(submit(FORM_NAME));
              }}
              loading={isSubmitting}
              primary
            >
              {__('Uložiť')}
            </Button>
          </ContextBarItem>
        </ContextBar>
      }
    >
      <Segment loading={isFetching}>
        {!isFetching && (
          <AdminSportGroundForm
            onSubmit={onSubmit}
            form={FORM_NAME}
            initialValues={initialValues || {}}
          />
        )}
      </Segment>
    </ScrollLayout>
  );
};

export default compose(connect(), withRouter)(AdminSportGround);
