const data = () => [
  {
    _id: '5c652651c1c0e67a13348df2',
    name: 'MŠK Žilina',
    appSpace: 'msk-zilina-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Žilina',
        zip: '010 01',
        country: 'SVK',
        number: '9',
        street: 'Športová',
        type: 'INVOICE',
        _id: '5c652652c1c0e67a13348df3',
        lat: '49.229141',
        lon: '18.744810150186595',
      },
    ],
  },
  {
    _id: '5d52b6b7e543fd02d7827d49',
    name: 'MFK Vranov nad Topľou 3',
    appSpace: 'mfk-vranov-nad-toplou-3-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Vranov nad Topľou',
        zip: '093 01',
        country: 'SVK',
        number: '1',
        street: 'B. Němcovej',
        type: 'INVOICE',
        _id: '5d52b6b7640f5c2890585c7b',
        lat: '48.8875983',
        lon: '21.6724988',
      },
    ],
  },
  {
    _id: '5d52b689e543fd02d7827cc7',
    name: 'MFK Zemplín Michalovce',
    appSpace: 'mfk-zemplin-michalovce-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Michalovce',
        zip: '071 01',
        country: 'SVK',
        number: '5',
        street: 'Hviezdoslavova',
        type: 'INVOICE',
        _id: '5d52b689640f5c2890585c4d',
        lat: '48.76288256067817',
        lon: '21.90921164353332',
      },
    ],
  },
  {
    _id: '5d52b6aae543fd02d7827d23',
    name: 'FA BENECOL Košice',
    appSpace: 'fa-benecol-kosice-playmaker',
    addresses: [
      {
        status: 'active',
        city: 'Košice - m. č. Dargovských hrdinov',
        zip: '04022',
        country: 'SVK',
        number: '37',
        street: 'Postupimská',
        type: 'INVOICE',
        _id: '5d52b6aa640f5c2890585c6e',
        lat: '48.7314164',
        lon: '21.286192446798346',
      },
    ],
  },
  {
    _id: '5d52b6b7e543fd02d7827d49',
    name: 'MFK Vranov nad Topľou 1',
    appSpace: 'mfk-vranov-nad-toplou-1-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Vranov nad Topľou',
        zip: '093 01',
        country: 'SVK',
        number: '1',
        street: 'B. Němcovej',
        type: 'INVOICE',
        _id: '5d52b6b7640f5c2890585c7b',
        lat: '48.8875983',
        lon: '21.6724988',
      },
    ],
  },
  {
    _id: '5d52b6bbe543fd02d7827d4f',
    name: 'Spartak Myjava',
    appSpace: 'spartak-myjava-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Myjava',
        zip: '907 01',
        country: 'SVK',
        number: '261/1',
        street: 'Hodžova',
        type: 'INVOICE',
        _id: '5d52b6bb640f5c2890585c7d',
        lat: '48.760292050000004',
        lon: '17.56633240408815',
      },
    ],
  },
  {
    _id: '5d52b6efe543fd02d7827dbb',
    name: 'ŠK Zemedar Poprad - Stráže',
    appSpace: 'sk-zemedar-poprad---straze-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Poprad',
        zip: '05801',
        country: 'SVK',
        number: '4131/22',
        street: 'Kukučínova',
        type: 'INVOICE',
        _id: '5d52b6ef640f5c2890585ca2',
        lat: '49.0589939',
        lon: '20.3093199',
      },
    ],
  },
  {
    _id: '5d52b76fe543fd02d7827f09',
    name: 'FK Spišská Nová Ves',
    appSpace: 'fk-spisska-nova-ves-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Spišská Nová Ves',
        zip: '05201',
        country: 'SVK',
        number: '848/10',
        street: 'Ing. O. Kožucha',
        type: 'INVOICE',
        _id: '5d52b76fdfc6c13b5fb7a4e7',
        lat: '48.940551',
        lon: '20.562267191192447',
      },
    ],
  },
  {
    _id: '5d52b771e543fd02d7827f11',
    name: 'MŠK Tesla Stropkov',
    appSpace: 'msk-tesla-stropkov-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Stropkov',
        zip: '091 01',
        country: 'SVK',
        number: '895',
        street: 'Matice slovenskej',
        type: 'INVOICE',
        _id: '5d52b771dfc6c13b5fb7a4ea',
        lat: '49.2041574',
        lon: '21.6483945',
      },
    ],
  },
  {
    _id: '5d52b8d5e543fd02d7828258',
    name: 'ŠK Slovan Bratislava',
    appSpace: 'sk-slovan-bratislava-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Bratislava',
        zip: '831 04',
        country: 'SVK',
        number: '4',
        street: 'Viktora Tegelhoffa',
        type: 'INVOICE',
        _id: '5d52b8d5dfc6c13b5fb7a606',
        lat: '48.1639995',
        lon: '17.1362168',
      },
    ],
  },
  {
    _id: '5d52ba4be543fd02d78285f1',
    name: 'FC Baník Prievidza',
    appSpace: 'fc-banik-prievidza-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Prievidza',
        zip: '97101',
        country: 'SVK',
        number: '110/37',
        street: 'Športová ulica',
        type: 'INVOICE',
        _id: '5d52ba4bdfc6c13b5fb7a73b',
        lat: '48.7778918',
        lon: '18.6232801',
      },
    ],
  },
  {
    _id: '5d52c8d81b411b599391e734',
    name: 'FC ViOn Zlaté Moravce - Vráble',
    appSpace: 'fc-vion-zlate-moravce---vrable-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Zlaté Moravce',
        zip: '953 01',
        country: 'SVK',
        number: '64',
        street: 'Továrenská',
        type: 'INVOICE',
        _id: '5d52c8d8dfc6c13b5fb7a908',
        lat: '48.3983053',
        lon: '18.405408162614876',
      },
    ],
  },
  {
    _id: '5d52c98d1b411b599391ed6d',
    name: 'OŠK Bešeňová',
    appSpace: 'osk-besenova-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Bešeňová',
        zip: '03483',
        country: 'SVK',
        number: '25',
        street: 'OcÚ, Bešeňová',
        type: 'INVOICE',
        _id: '5d52c98ddfc6c13b5fb7ab1d',
        lat: '49.099565999999996',
        lon: '19.438506094671638',
      },
    ],
  },
  {
    _id: '5aaae6fb2f711605024e9f15',
    name: 'CFT Academy - Prešov',
    appSpace: 'cft-academy---presov-playmakers',
    addresses: [
      {
        type: 'INVOICE',
        street: 'Hríbová',
        number: '1881/2',
        zip: '08221',
        city: 'Veľký Šariš',
        country: 'SVK',
        status: 'active',
        location: {
          coordinates: [21, 49],
          type: 'Point',
        },
        _id: '612e12c58d76e4469693db01',
        lat: '49.0566136',
        lon: '21.2394809',
      },
    ],
  },
  {
    _id: '5d52b4f8e543fd02d782794f',
    appSpace: 'partizan-bardejov-bsk-playmakers',
    name: 'Partizán Bardejov BŠK',
    addresses: [
      {
        status: 'active',
        city: 'Bardejov',
        zip: '085 01',
        country: 'SVK',
        number: '1',
        street: 'Družstevná',
        type: 'INVOICE',
        _id: '5d52b4f8640f5c2890585b24',
        lat: '49.2965908',
        lon: '21.2625715',
      },
    ],
  },
  {
    _id: '5d52b515e543fd02d78279b8',
    appSpace: 'mfk-zvolen-playmakers',
    name: 'MFK Zvolen',
    addresses: [
      {
        status: 'active',
        city: 'Lieskovec',
        zip: '962 21',
        country: 'SVK',
        number: '7',
        street: 'Hájik',
        type: 'INVOICE',
        _id: '5d52b515640f5c2890585b41',
        lat: '48.5807453',
        lon: '19.1915081',
      },
    ],
  },
  {
    _id: '5d52b52ae543fd02d78279f0',
    name: 'FC Petržalka',
    appSpace: 'fc-petrzalka-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Bratislava',
        zip: '851 01',
        country: 'SVK',
        number: '257/1869',
        street: 'Viedenská cesta',
        type: 'INVOICE',
        _id: '5d52b52a640f5c2890585b55',
        lat: '48.1273733',
        lon: '17.0905505',
      },
    ],
  },
  {
    _id: '5d52b56fe543fd02d7827a96',
    name: 'NŠK 1922 Bratislava',
    appSpace: 'nsk-1922-bratislava-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Bratislava - mestská časť Nové Mesto',
        zip: '83237',
        country: 'SVK',
        number: '12444',
        street: 'Tomášikova',
        type: 'INVOICE',
        _id: '5d52b56f640f5c2890585b8c',
        lat: '48.1675571',
        lon: '17.1483061',
      },
    ],
  },
  {
    _id: '5d52b58ae543fd02d7827ae1',
    name: 'MŠK Novohrad Lučenec',
    appSpace: 'msk-novohrad-lucenec-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Lučenec',
        zip: '98401',
        country: 'SVK',
        number: '305/13',
        street: 'Ulica mieru',
        type: 'INVOICE',
        _id: '5d52b58a640f5c2890585ba6',
        lat: '48.334362',
        lon: '19.6658878',
      },
    ],
  },
  {
    _id: '5d52b5b7e543fd02d7827b4d',
    name: 'MŠK Púchov',
    appSpace: 'msk-puchov-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Púchov',
        zip: '020 01',
        country: 'SVK',
        number: '834/29',
        street: 'Ul. 1. mája',
        type: 'INVOICE',
        _id: '5d52b5b6640f5c2890585bcb',
        lat: '49.1208674',
        lon: '18.325837',
      },
    ],
  },
  {
    _id: '5d52b5c3e543fd02d7827b61',
    name: 'ŠK 2011 Pečeňady',
    appSpace: 'sk-2011-pecenady-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Pečeňady',
        zip: '92207',
        country: 'SVK',
        number: '151',
        street: 'Pečeňady',
        type: 'INVOICE',
        _id: '5d52b5c3640f5c2890585bd2',
        lat: '48.480201750000006',
        lon: '17.717787773131114',
      },
    ],
  },
  {
    _id: '5d52b6b7e543fd02d7827d49',
    appSpace: 'mfk-vranov-nad-toplou-2-playmakers',
    name: 'MFK Vranov nad Topľou 2',
    addresses: [
      {
        status: 'active',
        city: 'Vranov nad Topľou',
        zip: '093 01',
        country: 'SVK',
        number: '1',
        street: 'B. Němcovej',
        type: 'INVOICE',
        _id: '5d52b6b7640f5c2890585c7b',
        lat: '48.8875983',
        lon: '21.6724988',
      },
    ],
  },
  {
    _id: '5d52b73de543fd02d7827e83',
    name: 'AS Trenčín',
    appSpace: 'as-trencin-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Trenčín',
        zip: '911 01',
        country: 'SVK',
        number: '2313',
        street: 'Mládežnícka',
        type: 'INVOICE',
        _id: '5d52b73d640f5c2890585ce6',
        lat: '48.8975348',
        lon: '18.0442291',
      },
    ],
  },
  {
    _id: '5d52ba27e543fd02d782859f',
    name: 'OK Častkovce',
    appSpace: 'ok-castkovce-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Častkovce',
        zip: '91627',
        country: 'SVK',
        number: '355',
        street: 'Častkovce',
        type: 'INVOICE',
        _id: '5d52ba27dfc6c13b5fb7a720',
        lat: '48.6888191',
        lon: '17.779849700076923',
      },
    ],
  },
  {
    _id: '5d52c8a91b411b599391e557',
    name: 'Centrum voľného času - JUNIOR, Tajovského 30, Banská Bystrica',
    appSpace: 'centrum-volneho-casu---junior-banska-bystrica-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Banská Bystrica',
        zip: '97404',
        country: 'SVK',
        number: '3761/30',
        street: 'Tajovského',
        type: 'INVOICE',
        lat: '48.7403293',
        lon: '19.1273968',
      },
    ],
  },
  {
    _id: '5d52c8c81b411b599391e692',
    name: 'FK Senica',
    appSpace: 'fk-senica-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Senica',
        zip: '905 01',
        country: 'SVK',
        number: '639/22',
        street: 'Sadová',
        type: 'INVOICE',
        _id: '5d52c8c8dfc6c13b5fb7a8d0',
        lat: '48.68398575',
        lon: '17.376029330007015',
      },
    ],
  },
  {
    _id: '5d52c8df1b411b599391e76d',
    name: 'FC Lokomotíva Košice',
    appSpace: 'fc-lokomotiva-kosice-playmakers',
    addresses: [
      {
        status: 'active',
        city: 'Košice - mestská časť Juh',
        zip: '040 01',
        country: 'SVK',
        number: '4E',
        street: 'Žižkova',
        type: 'INVOICE',
        _id: '5d52c8dfdfc6c13b5fb7a91b',
        lat: '48.7150621',
        lon: '21.2502136',
      },
    ],
  },
  {
    _id: '612dfd5c95436ac0275bbe12',
    name: 'ARC Sport Hviezdoslavov',
    appSpace: 'arc-sport-hviezdoslavov-playmakers',
    addresses: [
      {
        type: 'INVOICE',
        street: 'Hviezdoslavov',
        number: '1445',
        zip: '93041',
        city: 'Hviezdoslavov',
        country: 'SVK',
        status: 'active',
        location: {
          coordinates: [21, 49],
          type: 'Point',
        },
        _id: '612dfd978d76e4837293daf8',
        lat: '48.06361525',
        lon: '17.353348118659007',
      },
    ],
  },
  {
    _id: '5d52b5cae543fd02d7827b6f',
    name: 'FAMT Prešov',
    appSpace: 'famt-presov',
    addresses: [
      {
        status: 'active',
        city: 'Prešov',
        zip: '08001',
        country: 'SVK',
        number: '2766/9',
        street: 'Raymanova',
        type: 'INVOICE',
        _id: '5d52b5ca640f5c2890585bd7',
        lat: '48.98932455',
        lon: '21.250458071985246',
      },
    ],
  },
];

export default data;
