import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { CookieConsentConfig, run } from 'vanilla-cookieconsent';
import { RootState } from '../../configureStore';
import { RouteComponentProps } from 'react-router';
import { authUserSelector } from '../../pages/App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import React from 'react';

const pluginConfig: CookieConsentConfig = {
  autoClearCookies: true,

  mode: 'opt-in',

  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    marketing: {
      enabled: false,
      readOnly: false,
    },
    analytics: {
      enabled: false,
      readOnly: false,
    },
  },
  onConsent(param) {
    const analytics = param.cookie?.categories?.includes('analytics')
      ? 'granted'
      : 'denied';

    const marketing = param.cookie?.categories?.includes('marketing')
      ? 'granted'
      : 'denied';

    const consent = {
      analytics_storage: analytics,
      security_storage: analytics,
      personalization_storage: analytics,
      functionality_storage: analytics,
      ad_storage: marketing,
      ad_user_data: marketing,
      ad_personalization: marketing,
    };

    !!(window as any)?.gtag &&
      (window as any)?.gtag('consent', 'update', consent);

    if (marketing === 'granted') {
      (window as any)?.dataLayer?.push(['set', 'ads_data_redaction', false]);
    } else {
      (window as any)?.dataLayer?.push(['set', 'ads_data_redaction', true]);
    }

    (window as any)?.dataLayer?.push(['set', 'url_passthrough', true]);
  },

  language: {
    default: 'sk',
    translations: {
      sk: {
        consentModal: {
          title: 'Používame cookies!',
          description:
            'Súbory cookie používame na zhromažďovanie a analýzu informácií o výkone a používaní stránok, na poskytovanie funkcií sociálnych médií a na vylepšenie a prispôsobenie obsahu a reklám. <button type="button" data-cc="c-settings" class="cc-link">Nastavenie cookies</button>',
          acceptAllBtn: 'Akceptovať',
          acceptNecessaryBtn: 'Zamietnuť',
          showPreferencesBtn: 'Nastavenie cookies',
        },
        preferencesModal: {
          title: 'Nastavenie cookies',
          savePreferencesBtn: 'Uložiť nastavenia',
          acceptAllBtn: 'Akceptovať všetko',
          acceptNecessaryBtn: 'Zamietnuť všetko',
          closeIconLabel: 'Zatvoriť',

          sections: [
            {
              title: 'Použitie cookies 📢',
              description:
                'Súbory cookie používame na zhromažďovanie a analýzu informácií o výkone a používaní stránok, na poskytovanie funkcií sociálnych médií a na vylepšenie a prispôsobenie obsahu a reklám. Cookies sú kategorizované a viete sa rozhodnúť, ktorú z nich povolíte.',
            },
            {
              title: 'Nevyhnutné cookies',
              description:
                'Niektoré cookies sú potrebné na zabezpečenie základných funkcií. Bez týchto súborov cookie nebude web fungovať správne a sú predvolene povolené a nemožno ich deaktivovať.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Analytické cookies',
              description:
                'Analytické cookies nám pomáhajú zlepšovať našu webovú stránku zhromažďovaním a hlásením informácií o jej použití.',
              linkedCategory: 'analytics',
            },
            {
              title: 'Marketingové cookies',
              description:
                'Marketingové cookies sa používajú na sledovanie návštevníkov naprieč webovými stránkami, aby vydavatelia mohli zobrazovať relevantné a pútavé reklamy.',
              linkedCategory: 'marketing',
            },
          ],
        },
      },
    },
  },
};

const mapStateToProps = (state: RootState) => ({
  appSettings: authUserSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = IMapStateToProps & RouteComponentProps<void, void>;

const CookieConsent: React.FC<Props> = () => {
  React.useEffect(() => {
    run(pluginConfig);
  }, []);

  return null;
};

export default compose(withTheme, connect(mapStateToProps))(CookieConsent);
