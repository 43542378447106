import PublicPage from '../../components/PublicPage';
import React from 'react';
import Segment from '@sportnet/ui/Segment';
import __ from '../../utilities/__';

const SignUp: React.FC = () => {
  const title = __('Prihlás sa do projektu');
  return (
    <PublicPage title={title} metaTitle={title}>
      <Segment raised>
        <div style={{ textAlign: 'center' }}>
          <iframe
            title={title}
            src="https://docs.google.com/forms/d/e/1FAIpQLSdC1t-mF8G2RbVMz4Ki7xc42-wqNcZSf0fKObB5exWW9e1F1A/viewform?embedded=true"
            width="640"
            height="3173"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
          >
            Načítava sa…
          </iframe>
        </div>
      </Segment>
    </PublicPage>
  );
};

export default SignUp;
