import { Writeable } from '../library/App';

interface IEntity {
  [key: string]: any;
}

interface IEntities {
  [key: string]: IEntity;
}

const updateEntities = function (
  oldEntities: IEntities,
  entitiesToUpdate: IEntities,
): IEntities {
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return Object.keys(entities).reduce(
    (acc: typeof entities, entityName: keyof typeof entities) => {
      if (entityName in oldEntities) {
        acc[entityName] = Object.keys(entities[entityName]).reduce(
          (innerAcc: { [key: string]: any }, entityId: string) => {
            if (entityId in oldEntities[entityName]) {
              innerAcc[entityId] = {
                ...oldEntities[entityName][entityId],
                ...entities[entityName][entityId],
              };
            } else {
              innerAcc[entityId] = entities[entityName][entityId];
            }
            return innerAcc;
          },
          {},
        );
      } else {
        acc[entityName] = entities[entityName];
      }
      return acc;
    },
    {},
  );
};

export default updateEntities;
