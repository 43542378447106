import * as React from 'react';
import { Link } from 'react-router';
import { RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import { commit, getListTotal, isCommiting } from '@sportnet/redux-list/ducks';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { currentOrganizationSelector } from '../Schools/selectors';
import {
  currentUserIsFetchingSelector,
  currentUserSelector,
  listEventsSelector,
} from './selectors';
import { format, isSameDay } from 'date-fns';
import { getAvatarColor } from './UsersTable';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import { loadOrganization } from '../Schools/actions';
import { loadUser } from './actions';
import { rem } from 'polished';
import { useAsyncData } from 'ssr-service';
import Api from '../../api/Api';
import Article from '@sportnet/ui/Article';
import ArticleList from '@sportnet/ui/ArticleList';
import AvatarsIcon from './Avatar';
import PublicPage from '../../components/PublicPage';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import __ from '../../utilities/__';
import config from '../../config';
import styled from '../../theme/styled-components';
import useQuery, {
  NumberParam,
  StringParam,
} from '@sportnet/query-hoc/useQuery';

const mapStateToProps = (
  state: RootState,
  props: RouteProps<{ id: string; appSpace: string }>,
) => ({
  user: currentUserSelector(props.params.id)(state),
  organization: currentOrganizationSelector(props.params.appSpace)(state),
  isFetchingUser: currentUserIsFetchingSelector(props.params.id)(state),
  events: listEventsSelector(state),
  total: getListTotal(config.USER_EVENTS_LIST_NAME)(state),
  isFetchingEvents: isCommiting(config.USER_EVENTS_LIST_NAME)(state),
});
const mapDispatchToProps = {
  loadUserItem: loadUser.action,
  loadOrganizationItem: loadOrganization.action,
  setList: initializeOrSetListParams.action,
  commitList: commit.action,
  updateEvents: updateEntities,
};

const OrganizationInfo = styled.div`
  display: flex;
  align-items: center;
`;

const OrganizationName = styled.div`
  font-size: ${rem(24)};
  font-weight: 600;
  padding-left: ${rem(16)};
`;

const OrganizationType = styled.div`
  font-size: ${rem(14)};
  font-weight: 200;
  display: block;
  a {
    color: #333;
  }
`;

const EventsWrapper = styled.div`
  width: 100%;
  section {
    grid-gap: ${rem(10)};
  }
`;
const Pager = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: ${rem(8)};
`;
const Page = styled.div<{ active?: boolean }>`
  width: ${rem(12)};
  height: ${rem(12)};
  cursor: pointer;
  background: ${({ theme, active }) => (active ? theme.color.primary : '#aaa')};
  margin: 0 ${rem(4)};
  border-radius: 100%;
`;

type Props = RouteProps<{ id: string; appSpace: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const UserDetail: React.FC<Props> = ({
  params: { id, appSpace },
  router,
  location: { search, pathname },
  loadUserItem,
  loadOrganizationItem,
  user,
  organization,
  setList,
  commitList,
  updateEvents,
  events,
  total,
  isFetchingUser,
  isFetchingEvents,
}) => {
  useAsyncData(async () => {
    try {
      await loadUserItem({ id, appSpace });
    } catch (e: any) {
      alert(__('Nepodarilo sa získať informácie o žiakovi.'));
    } finally {
      //
    }
  }, []);

  useAsyncData(async () => {
    try {
      await loadOrganizationItem({ id: appSpace });
    } catch (e: any) {
      alert(__('Nepodarilo sa získať informácie o centre.'));
    } finally {
      //
    }
  }, []);

  const [page, setPage] = React.useState(1);

  const normalizeEvents = (data: any[]) => {
    return {
      entities: {
        events: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };

  const { query, setQuery } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    {
      parameters: {
        offset: NumberParam(0),
        season: StringParam(''),
      },
    },
  );

  const onChangePage = (newPage: number) => {
    const offset = (newPage - 1) * config.USER_EVENTS_LIST_LIMIT;
    setPage(newPage);
    setQuery({ offset });
  };

  React.useEffect(() => {
    const newPage = query.offset / config.USER_EVENTS_LIST_LIMIT + 1;
    setPage(newPage);
  }, [query.offset]);

  useAsyncData(async () => {
    setList({
      listName: config.USER_EVENTS_LIST_NAME,
      params: {
        ...query,
        id,
      },
    });
    await commitList({
      listName: config.USER_EVENTS_LIST_NAME,
      load: async () => {
        try {
          const res = await Api.getPlayerEvents(
            appSpace,
            config.PROJECT_NAME,
            id,
            {
              offset: query.offset,
              limit: config.USER_EVENTS_LIST_LIMIT,
            },
          );
          const { result, entities } = normalizeEvents(res.events);

          updateEvents(entities);
          return {
            results: result,
            total: res.total,
          };
        } catch (e: any) {
          console.error(e);
          alert(__('Nepodarilo sa získať zoznam športových udalostí'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  }, [id, query.offset]);

  const pages = Math.ceil((total || 0) / config.ORGANIZATION_EVENTS_LIST_LIMIT);

  const title = __('Informácie o žiakovi');

  return (
    <PublicPage title={title} metaTitle={user ? user.name : title}>
      {user && organization && (
        <>
          <Segment raised>
            <OrganizationInfo>
              <div>
                <AvatarsIcon size="50" color={getAvatarColor(user.gender)} />
              </div>
              <OrganizationName>
                <div>{user.name}</div>
                <OrganizationType>
                  <Link to={`/centrum/${organization._id}`}>
                    {organization.name}
                  </Link>
                </OrganizationType>
                <OrganizationType>
                  {__('Vek')}: {user.age}
                </OrganizationType>
              </OrganizationName>
            </OrganizationInfo>
          </Segment>
          {/* <Segment raised>
            <Table>
              <Tbody>
                <StyledTr>
                  <Td>
                    <b>{__('Miesto narodenia')}</b>
                  </Td>
                  <Td>{user.birthplace || '-'}</Td>
                </StyledTr>
                <StyledTr>
                  <Td>
                    <b>{__('Vek')}</b>
                  </Td>
                  <Td>{user.age}</Td>
                </StyledTr>
              </Tbody>
            </Table>
          </Segment> */}
          {(isFetchingEvents || events.length > 0) && (
            <EventsWrapper>
              <Segment
                header={
                  <SegmentHeader withSeparator size="s">
                    {__('Účasť na aktivitách')}
                  </SegmentHeader>
                }
                raised
                loading={!!isFetchingEvents}
              >
                <ArticleList>
                  {events.map((e: any) => {
                    let imgSrc = '/uefa-playmakers-white.jpg';
                    if (e.description && (e.description.widgets || []).length) {
                      const imgWidget = e.description.widgets.find(
                        (w: any) => w.type === 'photo',
                      );
                      if (imgWidget && imgWidget.picture) {
                        imgSrc = imgWidget.picture.public_url;
                      } else if (imgWidget && imgWidget.uri) {
                        imgSrc = `https://mediamanager.sportnet.online/images/230x172/${imgWidget.uri}`;
                      }
                    }
                    return (
                      <Link
                        key={e._id}
                        to={`/centrum/${e.appSpace}/aktivita/${e._id}`}
                      >
                        <Article
                          imgSrc={imgSrc}
                          title={`${e.name}`}
                          subtitle={
                            (
                              <>
                                {e.sportGround.name}
                                <br />
                                {`${format(
                                  new Date(e.startDate),
                                  config.DATE_TIME_FORMAT,
                                )} - ${format(
                                  new Date(e.endDate),
                                  isSameDay(
                                    new Date(e.startDate),
                                    new Date(e.endDate),
                                  )
                                    ? 'HH:mm'
                                    : config.DATE_TIME_FORMAT,
                                )}`}
                              </>
                            ) as any
                          }
                        />
                      </Link>
                    );
                  })}
                </ArticleList>
                {pages > 1 && (
                  <Pager>
                    {Array(pages)
                      .fill(0)
                      .map((i, idx) => {
                        return (
                          <Page
                            key={`pager_${idx}`}
                            onClick={() => onChangePage(idx + 1)}
                            active={idx + 1 === page}
                          />
                        );
                      })}
                  </Pager>
                )}
              </Segment>
            </EventsWrapper>
          )}
        </>
      )}
    </PublicPage>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UserDetail,
);
