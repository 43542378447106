import { createGlobalStyle } from './styled-components';

export default createGlobalStyle`
  html, body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    background: #fff;
    height: 100%;
  }
  #root {
    min-height: 100%;
  }
  div#menu-root div {
    z-index: 1000;
  }
  a {
    text-decoration: none;
    color: #37cdd7;
  }
  * {
    box-sizing: border-box;
  }
  .leaflet-popup-content {
    margin: 0;
  }
  .leaflet-top,
  .leaflet-bottom {
    z-index: 5;
  }
  .leaflet-container > * {
    z-index: 0;
  }
  /*Sentence*/
  .sentence {
    color: #222;
    font-size: 42px;
    text-align: left;
    font-family: 'Bangers', cursive;
  }
  /*FadeIn*/
  .fadeIn {
    display: inline;
    text-indent: 8px;
    margin-left: -12px;
  }
  .fadeIn span {
    animation: fadeEffect 7.5s linear infinite 0s;
    -ms-animation: fadeEffect 7.5s linear infinite 0s;
    -webkit-animation: fadeEffect 7.5s linear infinite 0s;
    color: #ff3c3c;
    opacity: 0;
    overflow: hidden;
    position: absolute;
  }
  .fadeIn span:nth-child(2) {
    animation-delay: 2.5s;
    -ms-animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
    color: #FF96AA
  }
  .fadeIn span:nth-child(3) {
    animation-delay: 5s;
    -ms-animation-delay: 5s;
    -webkit-animation-delay: 5s;
    color: #FFDC00;
  }

  /*FadeIn Animation*/
  @-moz-keyframes fadeEffect {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
      -moz-transform: translateY(0px);
    }
    10% {
      opacity: 1;
      -moz-transform: translateY(0px);
    }
    25% {
      opacity: 1;
      -moz-transform: translateY(0px);
    }
    30% {
      opacity: 0;
      -moz-transform: translateY(0px);
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes fadeEffect {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
      -webkit-transform: translateY(0px);
    }
    10% {
      opacity: 1;
      -webkit-transform: translateY(0px);
    }
    25% {
      opacity: 1;
      -webkit-transform: translateY(0px);
    }
    30% {
      opacity: 0;
      -webkit-transform: translateY(0px);
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @-ms-keyframes fadeEffect {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 0;
      -ms-transform: translateY(0px);
    }
    10% {
      opacity: 1;
      -ms-transform: translateY(0px);
    }
    25% {
      opacity: 1;
      -ms-transform: translateY(0px);
    }
    30% {
      opacity: 0;
      -ms-transform: translateY(0px);
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;
