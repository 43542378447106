import * as React from 'react';

const Avatars = () => (
  <svg name="no-result" width="60" height="60">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
      <path
        fill="#e8eef5"
        fillRule="evenodd"
        d="M52-.001c28.719 0 52 23.218 52 51.857s-23.281 51.858-52 51.858S0 80.495 0 51.856C0 23.217 23.281-.001 52-.001z"
      />
      <path
        fill="#d9e2ee"
        fillRule="evenodd"
        d="M86.121 75.133l9.99 4.173a52.166 52.166 0 0 1-20.107 18.548v-2.453c0-5.408-3.521-10.461-9.189-13.189l-.089-.043-11.037-4.657 6.496-3.009c2.578-.948 4.051-3.158 4.051-5.684v-4.103c0-.348-.12-.695-.281-1.042-7.043-.551-14.999-1.707-12.493-3.738 5.091-4.126 3.213-10.291 2.624-15.734-1.218-11.242 8.426-19.875 18.369-19.875 9.354 0 6.345 2.94 9.622 4.14 7.28 2.668 8.371 8.622 8.576 15.565.132 4.443-3.162 12.931 1.921 15.904 3.641 2.13-5.184 3.322-12.643 3.834-.137.316-.23.631-.23.946v4.42c0 2.525 1.842 5.05 4.42 5.997z"
      />
      <path
        fill="#d9e2ee"
        fillRule="evenodd"
        d="M52 103.714c-17.826 0-33.552-8.95-42.921-22.586l7.864-3.701c3.212-1.199 5.048-3.996 5.048-7.193V65.04c0-.8-.46-1.599-.918-2.398-2.753-3.196-4.588-6.793-5.965-10.389 0-.799-.459-1.599-1.376-1.998-.459-.4-.918-1.199-.918-1.998v-6.393c0-.4.459-1.199.459-1.598.918-.8 1.376-1.998 1.376-2.798v-8.791c0-2.797.918-5.194 2.294-7.192 8.718-5.993 21.565-5.993 30.742 0 2.294 1.998 3.211 4.795 2.753 7.592v8.391c0 1.199.458 1.998 1.377 2.798.458.399.917 1.198.917 1.598v6.393c0 1.199-.917 1.998-1.836 2.397a4.312 4.312 0 0 0-2.294 2.398c-1.376 2.797-2.753 5.594-5.047 8.391l-1.377 1.199c-.458.799-.917 1.598-.917 2.398v5.594c0 3.196 2.294 6.393 5.506 7.592l17.894 7.59c4.129 1.998 6.882 5.596 6.882 9.591v4.505A51.94 51.94 0 0 1 52 103.714z"
      />
    </svg>
  </svg>
);

export default Avatars;
