import * as React from 'react';
import { RootState } from '../../configureStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isCommiting } from '@sportnet/redux-list/ducks';
import { listUsersSelector } from './selectors';
import { loadUsers } from './actions';
import { removeAccents } from 'sportnet-utilities';
import { useAsyncData } from 'ssr-service';
import Api from '../../api/Api';
import Button from '@sportnet/ui/Button';
import Col, { Row } from '@sportnet/ui/Grid';
import Input from '@sportnet/ui/Input';
import PublicPage from '../../components/PublicPage';
import Segment from '@sportnet/ui/Segment';
import TheSelectSimple from '@sportnet/ui/TheSelectSimple';
import UsersTable from './UsersTable';
import __ from '../../utilities/__';
import config from '../../config';

const mapStateToProps = (state: RootState) => ({
  items: listUsersSelector(state),
  isFetchingItems: isCommiting(config.USERS_LIST_NAME)(state),
});

const mapDispatchToProps = {
  loadItems: loadUsers.action,
};

const UsersList: React.FC<
  ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    RouteComponentProps<{}, {}>
> = ({ router: { push }, location, loadItems, items, isFetchingItems }) => {
  const [isFetchingSeasons, setIsFetchingSeasons] = React.useState(false);
  const [seasons, setSeasons] = React.useState<
    Array<{ _id: string; name: string }>
  >([]);

  const [q, setQ] = React.useState('');
  const [season, setSeason] = React.useState<null | string>(null);

  const [limit, setLimit] = React.useState(50);

  useAsyncData(async () => {
    try {
      setIsFetchingSeasons(true);
      const { items: seasonItems } = await Api.getPublicSeasons(
        config.PROJECT_NAME,
      );
      setSeasons(seasonItems);
      if (!season) {
        // await loadItems({ id: items[0]._id });
        setSeason(seasonItems[0]._id);
      } else {
        await loadItems({ id: season });
      }
    } catch (e: any) {
      alert(__('Nepodarilo sa získať zoznam žiakov.'));
    } finally {
      setIsFetchingSeasons(false);
    }
  }, []);

  useAsyncData(async () => {
    if (season) {
      await loadItems({ id: season });
    }
  }, [season]);

  const filteredItems = [...items].filter((i) =>
    removeAccents(i.name).match(RegExp(removeAccents(q), 'i')),
  );

  return (
    <PublicPage title={__('Zoznam zapojených žiakov')}>
      <Segment raised>
        <Row>
          <Col xs={12} m={9}>
            <Input
              value={q}
              onChange={(e) => {
                setLimit(50);
                setQ(e.target.value);
              }}
              placeholder={__('Meno žiaka...')}
            >
              <Input.Icon name="search" />
              <input />
            </Input>
          </Col>
          <Col xs={12} s={6} m={3}>
            {!!window.matchMedia && (
              <TheSelectSimple
                value={season || (seasons.length ? seasons[0]._id : null)}
                clearable={false}
                loading={isFetchingSeasons}
                options={seasons.map((s) => ({
                  label: s.name,
                  value: s._id,
                }))}
                onChange={(e: string | null) => {
                  setLimit(50);
                  setSeason(e);
                }}
              />
            )}
          </Col>
        </Row>
      </Segment>
      <Segment raised loading={!!isFetchingItems}>
        <UsersTable withTime={true} items={filteredItems.slice(0, limit)} />
        {filteredItems.length > limit && (
          <div>
            <Button
              primary
              onClick={() => {
                setLimit(limit + 50);
              }}
            >
              {__('Zobraziť viac')}
            </Button>
          </div>
        )}
      </Segment>
    </PublicPage>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UsersList);
