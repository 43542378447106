import { ITheme } from '../../theme/theme';
import { RootState } from '../../configureStore';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from '../../theme/styled-components';
import React from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import config from '../../config';

type OwnProps = {
  theme: ITheme;
};

const mapStateToProps = (state: RootState) => ({});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & IMapStateToProps & RouteComponentProps<void, void>;

const Analytics: React.FC<Props> = () => {
  if (config.ANALYTICS.GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(config.ANALYTICS.GOOGLE_ANALYTICS_ID);
  }

  if (config.ANALYTICS.GOOGLE_TAG_MANAGER_ID) {
    TagManager.initialize({ gtmId: config.ANALYTICS.GOOGLE_TAG_MANAGER_ID });
  }

  return null;
};

export default compose(withTheme, connect(mapStateToProps))(Analytics);
