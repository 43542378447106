import * as React from 'react';
import { Field, change, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formatUserName, getProp } from 'sportnet-utilities';
import { rem } from 'polished';
import { required } from './validate';
import { useAsyncData } from 'ssr-service';
import Col, { Row } from '@sportnet/ui/Grid';
import CoreApi, { Codelist } from '../../api/CoreApi';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import Input from '@sportnet/ui/Input/index';
import __ from '../../utilities/__';
import styled from 'styled-components';

const LinkWrapper = styled.div<{ error?: string }>`
  margin-top: ${({ error }) => (error ? 0 : rem(-15))};
  margin-bottom: ${rem(15)};
  font-size: ${rem(12)};
  a:visited {
    color: inherit;
  }
`;

const CompetentPeopleForm: React.FC<any> = ({
  dispatch,
  form,
  licenseType,
  ...props
}) => {
  const [isFetchingUser, setIsFetchingUser] = React.useState(false);
  const [shirtSizes, setShirtSizes] = React.useState<Codelist>({
    codelist: [],
  });
  const [isFetchingShirtSizes, setIsFetchingShirtSizes] = React.useState(false);

  useAsyncData(async () => {
    try {
      setIsFetchingShirtSizes(true);
      const codelist = await CoreApi.getCodelist('shirt-size');
      setShirtSizes(codelist);
    } catch (e: any) {
      //
    } finally {
      setIsFetchingShirtSizes(false);
    }
  }, []);

  const fetchUser = async (event: any) => {
    if (!event.target.value) {
      return;
    }
    try {
      setIsFetchingUser(true);
      const user = await CoreApi.getPublicUserProfile(event.target.value);
      dispatch(
        change(form, 'user', {
          sportnetId: user._id,
          name: formatUserName(user),
        }),
      );
    } catch (e: any) {
      alert(
        __('Nebolo možné získať detail užívateľa podľa zadaného sportnet ID'),
      );
      dispatch(change(form, 'user', {}));
    } finally {
      setIsFetchingUser(false);
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <Field
          name="user.sportnetId"
          label={__('Sportnet ID')}
          component={FormFieldRedux}
          required
          validate={required}
          onBlur={fetchUser}
          loading={isFetchingUser}
        >
          <input />
          <Input.Button primary icon="search" onClick={fetchUser} />
        </Field>
        <LinkWrapper
          error={
            getProp(props, ['errors', 'user', 'sportnetId']) &&
            getProp(props, ['meta', 'user', 'sportnetId', 'touched'], false)
          }
        >
          <a
            href="https://my.sportnet.online"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            {__(
              'Nemá ešte účet zaregistrovaný v platforme Sportnet.Online? Zaregistrovať sa môže tu.',
            )}
          </a>
        </LinkWrapper>
      </Col>
      <Col xs={12}>
        <Field
          label={__('Meno a priezvisko')}
          name="user.name"
          component={FormFieldRedux}
          required
          disabled
          loading={isFetchingUser}
        />
      </Col>
      <Col xs={12}>
        <Field
          name="user.license_type"
          component={FormFieldRedux}
          label={__('Pozícia')}
          validate={[required]}
          type="theselect"
          required
          options={licenseType.map((license: any) => ({
            label: license.label,
            value: license.label,
          }))}
        />
      </Col>
      <Col xs={12}>
        <Field
          name="user.license_id"
          component={FormFieldRedux}
          label={__('Číslo licencie')}
          required
          validate={[required]}
          placeholder="vzor: T-UEFAC-123456"
        />
      </Col>
      <Col xs={12}>
        <Field
          name="user.shirt_size"
          component={FormFieldRedux}
          label={__('Veľkosť oblečenia')}
          required
          validate={[required]}
          loading={isFetchingShirtSizes}
          type="theselect"
          options={(shirtSizes.codelist || []).map((i) => ({
            label: i.label || i.value,
            value: i.value,
          }))}
        />
      </Col>
    </Row>
  );
};

export default compose(reduxForm({}), connect())(CompetentPeopleForm) as any;
