import { RootState } from '../../configureStore';
import { authUserSelector } from '../App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useAsyncData } from 'ssr-service';
import { withTheme } from 'styled-components';
import Button from '@sportnet/ui/Button';
import CmsApi from '../../api/CmsApi';
import NoResults from '../../components/NoResults';
import PublicPage from '../../components/PublicPage';
import React from 'react';
import RequestFormWrapper from './submit';
import Segment from '@sportnet/ui/Segment';
import Widgets from '@sportnet/content/view';
import __ from '../../utilities/__';
import config from '../../config';

const mapStateToProps = (state: RootState) => {
  return {
    authUser: authUserSelector(state),
  };
};

type Props = ReturnType<typeof mapStateToProps>;

const Demand: React.FC<Props> = ({ authUser }) => {
  const [content, setContent] = React.useState<any[]>([]);
  const [isFetchingContent, setIsFetchingContent] = React.useState(false);

  useAsyncData(async () => {
    try {
      setIsFetchingContent(true);
      const res = await CmsApi.getPublicStaticContentById(
        'pages',
        config.APP_ID,
        'default',
        'ziadost',
      );
      setContent(res.content || []);
    } catch (e: any) {
      //
    } finally {
      setIsFetchingContent(false);
    }
  }, []);

  const getAuthUrl = () => {
    const params: { [key: string]: string } = {
      client_id: config.APP_ID,
      response_type: 'token',
      redirect_uri: `${window.location.protocol}//${window.location.host}/admin`,
      state: 'ziadost',
      scope: 'profile,messages',
    };
    const authBaseUrl =
      process.env.REACT_APP_OAUTH_AUTH_BASE_URL ||
      'https://my.sportnet.online/auth/authorize';
    return `${authBaseUrl}?${Object.keys(params)
      .map((p) => {
        return `${encodeURIComponent(p)}=${encodeURIComponent(params[p])}`;
      })
      .join('&')}`;
  };

  const title = __('Registrácia do projektu');
  return (
    <PublicPage title={title}>
      <Segment raised loading={isFetchingContent}>
        <Widgets items={content} />
      </Segment>
      {authUser && <RequestFormWrapper />}
      {!authUser && (
        <div style={{ textAlign: 'center' }}>
          <NoResults
            title={__('Pre zobrazenie žiadosti je potrebné prihlásenie')}
          />
          <Button
            primary
            onClick={() => {
              window.location = getAuthUrl() as unknown as Location;
            }}
          >
            {__('Prihlásiť sa')}
          </Button>
        </div>
      )}
    </PublicPage>
  );
};

export default compose(withTheme, connect(mapStateToProps))(Demand);
