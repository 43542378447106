import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

const NoResultsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: ${rem(80)} 0 ${rem(60)} 0;
`;

const NoResultsTitle = styled.div`
  display: flex;
  flex: 100%;
  justify-content: center;
  color: ${({ theme }) => theme.primaryLight};
  letter-spacing: ${rem(0)};
  font-size: ${rem(24)};
  font-weight: 600;
  text-align: center;
`;
const NoResultsSubtitle = styled.div`
  display: flex;
  flex: 100%;
  justify-content: center;
  color: ${({ theme }) => theme.primaryLight};
  letter-spacing: ${rem(0)};
  font-size: ${rem(14)};
  line-height: 1.5;
  padding-top: ${rem(11)};
`;

type Props = {
  image?: any;
  title: string;
  subtitle?: string;
};

const NoResults: React.FC<Props> = ({ image, title, subtitle }) => {
  return (
    <NoResultsWrapper>
      {image}
      <NoResultsTitle>{title}</NoResultsTitle>
      {subtitle && <NoResultsSubtitle>{subtitle}</NoResultsSubtitle>}
    </NoResultsWrapper>
  );
};

export default NoResults;
