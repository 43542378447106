import * as React from 'react';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/ContextBar';
import { IEvent } from './types';
import { ReduxConnectProps } from '../../configureStore';
import { RouteProps } from '../../library/App';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { submit } from 'redux-form';
import { useAsyncData } from 'ssr-service';
import { withRouter } from 'react-router';
import AdminEventForm from './form';
import Api from '../../api/Api';
import Button from '@sportnet/ui/Button';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import Segment from '@sportnet/ui/Segment';
import __ from '../../utilities/__';
import config from '../../config';

const FORM_NAME = 'EVENT_FORM';

type Props = RouteProps<{ appSpace: string; id: string }> & ReduxConnectProps;

const AdminEvent: React.FC<Props> = ({
  params: { appSpace, id },
  router,
  dispatch,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<null | IEvent>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  useAsyncData(async () => {
    if (id !== 'new') {
      setIsFetching(true);
      try {
        const res = await Api.adminGetEventById(
          appSpace,
          config.PROJECT_NAME,
          id,
        );
        setInitialValues({ ...res, teams: res.teams || [] });
      } catch (e: any) {
        alert(__('Nepodarilo sa získať podrobnosti aktivity'));
      } finally {
        setIsFetching(false);
      }
    }
  }, [appSpace, id]);

  const onSubmit = async (data: any) => {
    const startDateObj = new Date(data.startDate);
    const endDateObj = new Date(data.endDate);
    endDateObj.setDate(startDateObj.getDate());
    endDateObj.setMonth(startDateObj.getMonth());
    endDateObj.setFullYear(startDateObj.getFullYear());
    const submitData: any = {
      name: data.name,
      startDate: format(startDateObj, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      endDate: format(endDateObj, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      sportGround: { _id: data.sportGround._id || data.sportGround },
      description: data.description,
      publication: data.publication,
      // seasonId: data.seasonId,
    };
    try {
      setIsSubmitting(true);
      if (id !== 'new') {
        // update
        await Api.adminUpdateEvent(
          appSpace,
          config.PROJECT_NAME,
          id,
          undefined,
          submitData,
        );
      } else {
        submitData.seasonId = data.seasonId;
        const res = await Api.adminCreateEvent(
          appSpace,
          config.PROJECT_NAME,
          undefined,
          {
            ...submitData,
            teamId: data.teamId,
          },
        );
        router.push(`/admin/appSpace/${appSpace}/events/${res._id}`);
      }
    } catch (e: any) {
      alert(__('Zmeny sa nepodarilo uložiť'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteEvent = async () => {
    if (window.confirm(__('Skutočne si želáte zmazať aktivitu?'))) {
      try {
        setIsDeleting(true);
        await Api.adminDeleteEvent(appSpace, config.PROJECT_NAME, id);
        router.push(`/admin/appSpace/${appSpace}/events`);
      } catch (e: any) {
        alert(__('Aktivitu sa nepodarilo zmazať.'));
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <ScrollLayout
      bottomFixed={
        <ContextBar>
          <ContextBarSpacer />
          <ContextBarItem>
            <Button
              onClick={() => {
                dispatch(submit(FORM_NAME));
              }}
              loading={isSubmitting}
              primary
            >
              {__('Uložiť')}
            </Button>
          </ContextBarItem>
          {id !== 'new' && (
            <>
              <Button loading={isDeleting} onClick={deleteEvent} danger>
                {__('Zmazať')}
              </Button>
            </>
          )}
        </ContextBar>
      }
    >
      <Segment loading={isFetching}>
        {!isFetching && document && (
          <AdminEventForm
            update={id !== 'new'}
            onSubmit={onSubmit}
            form={FORM_NAME}
            initialValues={
              initialValues && Object.keys(initialValues).length > 0
                ? { ...initialValues, teamId: initialValues.teams[0]._id }
                : { publication: true }
            }
          />
        )}
      </Segment>
    </ScrollLayout>
  );
};

export default compose(connect(), withRouter)(AdminEvent);
