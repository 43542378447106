import * as React from 'react';
import { Link } from 'react-router';
import { RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import { commit, getListTotal, isCommiting } from '@sportnet/redux-list/ducks';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  currentOrganizationIsFetchingSelector,
  currentOrganizationSelector,
  listEventsSelector,
  listSeasonsSelector,
  listTeamsSelector,
} from './selectors';
import { format, isSameDay } from 'date-fns';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import { loadOrganization } from './actions';
import { rem } from 'polished';
import { useAsyncData } from 'ssr-service';
import Api from '../../api/Api';
import Article from '@sportnet/ui/Article';
import ArticleList from '@sportnet/ui/ArticleList';
import AvatarsIcon from './Avatars';
import PublicPage from '../../components/PublicPage';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import TheSelectSimple from '@sportnet/ui/TheSelectSimple';
import __ from '../../utilities/__';
import config from '../../config';
import getData from '../Home/data';
import styled from '../../theme/styled-components';
import useQuery, {
  NumberParam,
  StringParam,
} from '@sportnet/query-hoc/useQuery';

const mapStateToProps = (
  state: RootState,
  props: RouteProps<{ id: string }>,
) => ({
  organization: currentOrganizationSelector(props.params.id)(state),
  isFetchingOrganization: currentOrganizationIsFetchingSelector(
    props.params.id,
  )(state),
  events: listEventsSelector(state),
  total: getListTotal(config.ORGANIZATION_EVENTS_LIST_NAME)(state),
  isFetchingEvents: isCommiting(config.ORGANIZATION_EVENTS_LIST_NAME)(state),
  seasons: listSeasonsSelector(state),
  isFetchingSeasons: isCommiting(config.SEASONS_LIST_NAME)(state),
  teams: listTeamsSelector(state),
  isFetchingTeams: isCommiting(config.ORGANIZATION_TEAMS_LIST_NAME)(state),
});
const mapDispatchToProps = {
  loadOrganizationItem: loadOrganization.action,
  setList: initializeOrSetListParams.action,
  commitList: commit.action,
  updateEvents: updateEntities,
};

const Contact = styled.div`
  font-size: ${rem(14)};
  margin-top: ${rem(16)};
`;

const OrganizationInfo = styled.div`
  display: flex;
  align-items: center;
`;

const OrganizationName = styled.div`
  font-size: ${rem(24)};
  font-weight: 600;
  padding-left: ${rem(16)};
`;

const OrganizationType = styled.div`
  font-size: ${rem(14)};
  font-weight: 200;
  display: block;
`;

const EventsWrapper = styled.div`
  width: 100%;
  section {
    grid-gap: ${rem(10)};
  }
`;
const Pager = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: ${rem(8)};
`;
const Page = styled.div<{ active?: boolean }>`
  width: ${rem(12)};
  height: ${rem(12)};
  cursor: pointer;
  background: ${({ theme, active }) => (active ? theme.color.primary : '#aaa')};
  margin: 0 ${rem(4)};
  border-radius: 100%;
`;

type Props = RouteProps<{ id: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

const SchoolDetail: React.FC<Props> = ({
  params: { id },
  router,
  location: { search, pathname },
  loadOrganizationItem,
  organization,
  setList,
  commitList,
  updateEvents,
  events,
  total,
  seasons,
  isFetchingOrganization,
  isFetchingEvents,
  isFetchingSeasons,
  teams,
  isFetchingTeams,
}) => {
  useAsyncData(async () => {
    try {
      await loadOrganizationItem({ id });
    } catch (e: any) {
      alert(__('Nepodarilo sa získať informácie o centre.'));
    } finally {
      //
    }
  }, []);

  const [page, setPage] = React.useState(1);

  const normalizeEvents = (data: any[]) => {
    return {
      entities: {
        events: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };

  const normalizeSeasons = (data: any[]) => {
    return {
      entities: {
        seasons: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };

  const normalizeTeams = (data: any[]) => {
    return {
      entities: {
        teams: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: any },
      },
      result: data.map((item) => item._id),
    };
  };

  const { query, setQuery: setParameter } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    {
      parameters: {
        offset: NumberParam(0),
        teamsSeason: StringParam(''),
        eventsSeason: StringParam(''),
      },
    },
  );

  const onChangePage = (newPage: number) => {
    const offset = (newPage - 1) * config.ORGANIZATION_EVENTS_LIST_LIMIT;
    setPage(newPage);
    setParameter({ offset });
  };

  React.useEffect(() => {
    const newPage = query.offset / config.ORGANIZATION_EVENTS_LIST_LIMIT + 1;
    setPage(newPage);
  }, [query.offset]);

  useAsyncData(async () => {
    setList({
      listName: config.SEASONS_LIST_NAME,
      params: {
        offset: 0,
      },
    });
    await commitList({
      listName: config.SEASONS_LIST_NAME,
      load: async () => {
        try {
          const res = await Api.getPublicSeasons(config.PROJECT_NAME);
          const { result, entities } = normalizeSeasons(res.items);

          updateEvents(entities);
          if (!query.teamsSeason) {
            await getTeams(res.items[0]._id);
          } else {
            await getTeams(query.teamsSeason as string);
          }
          if (!query.eventsSeason) {
            await getEvents(res.items[0]._id);
            setParameter({ eventsSeason: res.items[0]._id });
          } else {
            await getEvents(query.eventsSeason as string);
          }
          return {
            results: result,
            total: 0,
          };
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam ročníkov'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  }, [id]);

  const getTeams = async (seasonId: string) => {
    setList({
      listName: config.ORGANIZATION_TEAMS_LIST_NAME,
      params: {
        seasonId,
        id,
      },
    });
    await commitList({
      listName: config.ORGANIZATION_TEAMS_LIST_NAME,
      load: async () => {
        try {
          const teamsRes = await Api.publicGetTeams(id, config.PROJECT_NAME, {
            seasonId,
          });
          const { result, entities } = normalizeTeams(teamsRes.teams);

          updateEvents(entities);
          return {
            results: result,
            total: 0,
          };
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam žiakov'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  };

  useAsyncData(async () => {
    if (query.teamsSeason) {
      getTeams(query.teamsSeason as string);
    }
  }, [query.teamsSeason]);

  useAsyncData(async () => {
    if (query.eventsSeason) {
      getEvents(query.eventsSeason as string);
    }
  }, [query.offset, query.eventsSeason]);

  const getEvents = async (season: string) => {
    setList({
      listName: config.ORGANIZATION_EVENTS_LIST_NAME,
      params: {
        ...query,
        appSpace: id,
      },
    });
    await commitList({
      listName: config.ORGANIZATION_EVENTS_LIST_NAME,
      load: async () => {
        try {
          const res = await Api.publicGetEvents(config.PROJECT_NAME, {
            appSpace: id,
            seasonId: season,
            offset: query.offset,
            limit: config.ORGANIZATION_EVENTS_LIST_LIMIT,
          });
          const { result, entities } = normalizeEvents(res.events);

          updateEvents(entities);
          return {
            results: result,
            total: res.total,
          };
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam športových udalostí'));
          return {
            results: [],
            total: 0,
          };
        }
      },
    });
  };

  const pages = Math.ceil((total || 0) / config.ORGANIZATION_EVENTS_LIST_LIMIT);

  const title = __('Informácie o centre');

  const schools = getData();
  const dataItem = schools.find(
    (i) => organization && i.appSpace === organization._id,
  );

  return (
    <PublicPage
      title={title}
      metaTitle={organization ? organization.name : __('Informácie o centre')}
    >
      {organization && (
        <>
          <Segment raised>
            <OrganizationInfo>
              <div>
                <AvatarsIcon />
              </div>
              <OrganizationName>
                <div>{organization.name}</div>
                <OrganizationType>{__('Športové centrum')}</OrganizationType>
              </OrganizationName>
            </OrganizationInfo>
            <Contact>
              {dataItem && (
                <div>
                  <b>{__('Adresa:')}</b>&nbsp;
                  <span>
                    {`${dataItem.addresses[0].street} ${dataItem.addresses[0].number}, ${dataItem.addresses[0].zip} ${dataItem.addresses[0].city}`}
                  </span>
                </div>
              )}
              {organization.contact && organization.contact.phone && (
                <div>
                  <b>{__('Telefónne číslo:')}</b>&nbsp;
                  <a href={`tel:${organization.contact.phone}`}>
                    {organization.contact.phone}
                  </a>
                </div>
              )}
              {organization.contact && organization.contact.email && (
                <div>
                  <b>{__('E-mail:')}</b>&nbsp;
                  <a href={`mailto:${organization.contact.email}`}>
                    {organization.contact.email}
                  </a>
                </div>
              )}
            </Contact>
          </Segment>
          <EventsWrapper>
            <Segment
              header={
                <SegmentHeader size="s" withSeparator>
                  {
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>{__('Zrealizované aktivity')}</div>
                      <div>
                        {!!window.matchMedia && (
                          <TheSelectSimple
                            clearable={false}
                            value={
                              (query.eventsSeason as string) ||
                              (seasons.length ? (seasons as any)[0]._id : null)
                            }
                            onChange={(i) =>
                              setParameter({
                                eventsSeason: i ? String(i) : '',
                                offset: 0,
                              })
                            }
                            options={seasons.map((s: any) => ({
                              label: s.name,
                              value: s._id,
                            }))}
                          />
                        )}
                      </div>
                    </div>
                  }
                </SegmentHeader>
              }
              raised
              loading={!!isFetchingEvents}
            >
              <ArticleList>
                {events.map((e: any) => {
                  let imgSrc = '/uefa-playmakers-white.jpg';
                  if (e.description && (e.description.widgets || []).length) {
                    const imgWidget = e.description.widgets.find(
                      (w: any) => w.type === 'photo',
                    );
                    if (imgWidget && imgWidget.picture) {
                      imgSrc = imgWidget.picture.public_url;
                    } else if (imgWidget && imgWidget.uri) {
                      imgSrc = `https://mediamanager.sportnet.online/images/230x172/${imgWidget.uri}`;
                    }
                  }
                  return (
                    <Link
                      key={e._id}
                      to={`/centrum/${e.appSpace}/aktivita/${e._id}`}
                    >
                      <Article
                        imgSrc={imgSrc}
                        title={`${e.name}`}
                        subtitle={
                          (
                            <>
                              {e.sportGround.name}
                              <br />
                              {`${format(
                                new Date(e.startDate),
                                config.DATE_TIME_FORMAT,
                              )} - ${format(
                                new Date(e.endDate),
                                isSameDay(
                                  new Date(e.startDate),
                                  new Date(e.endDate),
                                )
                                  ? 'HH:mm'
                                  : config.DATE_TIME_FORMAT,
                              )}`}
                            </>
                          ) as any
                        }
                      />
                    </Link>
                  );
                })}
              </ArticleList>
              {pages > 1 && (
                <Pager>
                  {Array(pages)
                    .fill(0)
                    .map((i, idx) => {
                      return (
                        <Page
                          key={`pager_${idx}`}
                          onClick={() => onChangePage(idx + 1)}
                          active={idx + 1 === page}
                        />
                      );
                    })}
                </Pager>
              )}
            </Segment>
          </EventsWrapper>
        </>
      )}
    </PublicPage>
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SchoolDetail,
);
