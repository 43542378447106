import * as actions from './actions';
import { AnyAction } from 'redux';
import { AsyncActionCreators } from 'typescript-fsa';
import { IUser } from '../Users/types';
import { User } from '@sportnet/utilities/formatUserName';
import { Writeable } from '../../library/App';
import { getProp } from 'sportnet-utilities';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import serializeParameters from '../../utilities/serializeParameters';

export type EntitiesState = Readonly<{
  events: Readonly<{
    [key: string]: Readonly<any>;
  }>;
  sportGrounds: Readonly<{
    [key: string]: Readonly<any>;
  }>;
  teams: Readonly<{
    [key: string]: Readonly<any>;
  }>;
  organizations: Readonly<{ [key: string]: Readonly<any> }>;
  seasons: Readonly<{ [key: string]: Readonly<any> }>;
  users: Readonly<{
    [key: string]: Readonly<IUser>;
  }>;
}>;

export const entitiesReducer = (
  state: EntitiesState = {
    events: {},
    sportGrounds: {},
    teams: {},
    organizations: {},
    seasons: {},
    users: {},
  },
  action: AnyAction,
) => {
  if (getProp(action.payload, ['result', 'entities'])) {
    return Object.keys(action.payload.result.entities).reduce(
      (acc, entity: keyof EntitiesState) => {
        acc[entity] = Object.keys(
          action.payload.result.entities[entity],
        ).reduce(
          (innerAcc: Writeable<EntitiesState[keyof EntitiesState]>, id) => {
            innerAcc[id] = {
              ...innerAcc[id],
              ...action.payload.result.entities[entity][id],
            };
            return innerAcc;
          },
          { ...getProp(state, [entity], {}) },
        );
        return acc;
      },
      { ...state },
    );
  }
  return state;
};

export interface IDetailInitialState<
  R extends any | undefined = undefined,
  E extends any = any
> {
  [key: string]: {
    isFetching: boolean;
    error: E;
    data?: R;
  };
}

export const detailReducerFromAction = <D, E>(
  asyncActionCreators: {
    async: AsyncActionCreators<
      { [key: string]: any },
      { data?: D; [key: string]: any },
      E
    >;
  }[],
  ...parameterKeys: string[]
) =>
  reducerWithInitialState<IDetailInitialState<D>>({})
    .cases(
      asyncActionCreators.map(a => a.async.started),
      (state, params) => {
        const key = serializeParameters(params, parameterKeys);
        return {
          ...state,
          [key]: {
            ...state[key],
            isFetching: true,
          },
        };
      },
    )
    .cases(
      asyncActionCreators.map(a => a.async.done),
      (state, { params, result: { data } }) => {
        const key = serializeParameters(params, parameterKeys);
        return {
          ...state,
          [key]: {
            ...state[key],
            data,
            isFetching: false,
            error: null,
          },
        };
      },
    )
    .cases(
      asyncActionCreators.map(a => a.async.failed),
      (state, { params, error }) => {
        const key = serializeParameters(params, parameterKeys);
        return {
          ...state,
          [key]: {
            ...state[key],
            isFetching: false,
            error,
          },
        };
      },
    );

export type ApplicationState = {
  readonly appspaces: actions.Appspace[];
  readonly activeAppspace: string | null;
  readonly authUser: User | null;
};

const APPLICATION_INITIAL_STATE: ApplicationState = {
  appspaces: [],
  authUser: null,
  activeAppspace: null,
};

export const applicationReducer = reducerWithInitialState(
  APPLICATION_INITIAL_STATE,
)
  .case(actions.setAppspaces, (state, appspaces) => ({
    ...state,
    appspaces,
  }))
  .case(actions.setActiveAppspace, (state, activeAppspace) => ({
    ...state,
    activeAppspace,
  }))
  .case(actions.setAuthUser, (state, authorizationResponse) => ({
    ...state,
    authUser: authorizationResponse.user,
  }))
  .case(actions.removeActiveAppspace, state => ({
    ...state,
    activeAppspace: null,
  }));
