import * as React from 'react';
import { useAsyncData } from 'ssr-service';
import Api from '../../api/Api';
import FormField from '@sportnet/ui/FormField';
import __ from '../../utilities/__';
import config from '../../config';

type OwnProps = {
  handleClose: () => void;
};

const RedirectToRegistration: React.FC<OwnProps> = ({ handleClose }) => {
  const [isFetchingOrganizations, setIsFetchingOrganizations] =
    React.useState(false);
  const [organizations, setOrganizations] = React.useState<
    Array<{
      _id: string;
      name: string;
      type: string;
    }>
  >([]);

  useAsyncData(async () => {
    try {
      setIsFetchingOrganizations(true);
      const { items: seasons } = await Api.getPublicSeasons(
        config.PROJECT_NAME,
      );
      const { items } = await Api.getPublicOrgranizations(config.PROJECT_NAME, {
        seasonId: seasons[0]._id,
      });
      setOrganizations(items);
    } catch (e: any) {
      console.error(e);
      alert(__('Nepodarilo sa získať zoznam škôl'));
      handleClose();
    } finally {
      setIsFetchingOrganizations(false);
    }
  }, []);

  return (
    <FormField
      type="theselectsimple"
      loading={isFetchingOrganizations}
      options={organizations.map((o) => ({ label: o.name, value: o._id }))}
      label={__('Vyberte školu')}
      onChange={(i: string) => {
        window.location.href = `https://my.sportnet.online/invitation/${i}?register_parent=1`;
        handleClose();
      }}
    />
  );
};

export default RedirectToRegistration;
