import * as React from 'react';
import { IUser } from './types';
import { Link, RouteComponentProps, withRouter } from 'react-router';
import Avatar from './Avatar';
import BasicTable from '@sportnet/ui/BasicTable';
import __ from '../../utilities/__';

type OwnProps = {
  items: IUser[];
  withTime?: boolean;
};

type Props = OwnProps & RouteComponentProps<{}, {}>;

export const getAvatarColor = (gender?: string) => {
  if (gender === 'M') {
    return '#C3DCEE';
  } else if (gender === 'F') {
    return '#ECC2E2';
  }
  return '#ccc';
};

const formatTime = (minutesCount: number) => {
  const hours = minutesCount / 60;
  const rHours = Math.floor(hours);
  const minutes = (hours - rHours) * 60;
  const rMinutes = Math.round(minutes);
  return `${String(rHours).padStart(2, '0')}h ${String(rMinutes).padStart(
    2,
    '0',
  )}m`;
};

const UsersTable: React.FC<Props> = ({ items, withTime, router: { push } }) => {
  return (
    <BasicTable
      rows={[...items]}
      onClickRow={i => push(`/centrum/${i.orgProfileId}/ziaci/${i._id}`)}
      renderRow={i => [
        <Avatar
          key={i.orgProfileId}
          size="30"
          color={getAvatarColor(i.gender)}
        />,
        <Link
          key={i.orgProfileId}
          style={{ color: '#333' }}
          to={`/centrum/${i.orgProfileId}/ziaci/${i._id}`}
        >
          {i.name}
        </Link>,
        i.birthYear,
        i.orgProfileName,
        withTime && formatTime(i.playedMinutes),
      ]}
      columns={[
        { header: '', width: 50 },
        { header: __('Meno a priezvisko') },
        { header: __('Rok narodenia'), width: 150 },
        items[0] && items[0].orgProfileName
          ? { header: __('Škola') }
          : { header: '' },
        withTime
          ? { header: __('Čas na aktivitách'), width: 100 }
          : { header: '' },
      ]}
    />
  );
};

export default withRouter<OwnProps>(UsersTable);
