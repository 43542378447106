import * as React from 'react';
import { IndexRoute, Route, Router } from 'react-router';
import AdminEventForm from './pages/AdminEvents';
import AdminEventsList from './pages/AdminEvents/list';
import AdminSportGround from './pages/AdminSportGrounds';
import AdminSportGroundsList from './pages/AdminSportGrounds/list';
import AdminTeam from './pages/AdminTeams';
import AdminTeamsList from './pages/AdminTeams/list';
import App from './pages/App';
import Authorization from './containers/Authorization';
import Demand from './pages/Demand';
import EventDetail from './pages/Events';
import Home from './pages/Home';
import SchoolDetail from './pages/Schools';
import SchoolsList from './pages/Schools/list';
import SignUp from './pages/SignUp';
import UserDetail from './pages/Users';
import UsersList from './pages/Users/list';

const Routes: React.FC<any> = (props) => {
  return (
    <Router {...props}>
      <Route path="/" component={App}>
        <IndexRoute component={Home} />
        <Route path="centra" component={SchoolsList} />
        <Route path="centrum/:id" component={SchoolDetail} />
        <Route path="ziaci" component={UsersList} />
        <Route path="centrum/:appSpace/ziaci/:id" component={UserDetail} />
        <Route path="centrum/:appSpace/aktivita/:id" component={EventDetail} />
        <Route path="ziadost" component={Demand} />
        <Route path="prihlas-sa" component={SignUp} />
        <Route path="admin" component={Authorization}>
          <Route
            path="appSpace/:appSpace"
            component={({ children }) => <>{children}</>}
          >
            <Route path="events" component={AdminEventsList} />
            <Route path="events/:id" component={AdminEventForm} />
            <Route path="sportgrounds" component={AdminSportGroundsList} />
            <Route path="sportgrounds/:id" component={AdminSportGround} />
            <Route path="teams" component={AdminTeamsList} />
            <Route path="teams/:id" component={AdminTeam} />
          </Route>
        </Route>
      </Route>
    </Router>
  );
};

export default Routes;
