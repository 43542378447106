import * as React from 'react';
import { ITheme } from '../../theme/theme';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { defaultTheme as contactFormDefaultTheme } from '@sportnet/web-contact-form/theme';
import { setAuthUser } from './actions';
import Analytics from '../../containers/Analytics';
import ConsentPanel from '../../containers/Analytics/ConsentPanel';
import ContactForm from '@sportnet/web-contact-form/containers/App';
import Cookies from 'js-cookie';
import CoreApi from '../../api/CoreApi';
import Helmet from 'react-helmet';
import config from '../../config';
import styled, { ThemeProvider, withTheme } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

type OwnProps = {
  theme: ITheme;
  children?: React.ReactNode;
};

const mapStateToProps = (state: RootState) => ({
  // appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps &
  IMapStateToProps &
  RouteComponentProps<void, void> &
  ReduxConnectProps;

const App: React.FC<Props> = ({
  dispatch,
  children,
  location: { pathname },
  theme,
}) => {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const accessToken = Cookies.get('access_token');
      if (accessToken) {
        CoreApi.setToken(accessToken);
        CoreApi.me().then((user) => {
          dispatch(setAuthUser({ user, accessToken }));
        });
      }
      try {
        window.scroll({ left: 0, top: 0, behavior: 'smooth' });
      } catch (e: any) {
        // fallback for browsers without support for ScrollToOptions
        window.scroll(0, 0);
      }
    }
  }, [pathname, dispatch]);

  return (
    <Wrapper>
      <Helmet titleTemplate="Playmakers - %s" />
      <div id="contact-form-btn">
        <ThemeProvider
          theme={{
            ...contactFormDefaultTheme,
            color: {
              primary: theme.color.primary,
            },
            openButton: {
              color: '#fff',
              backgroundColor: theme.color.primary,
            },
            button: {
              color: '#fff',
              backgroundColor: theme.color.primary,
            },
          }}
        >
          <ContactForm appSpace={config.APP_ID} />
        </ThemeProvider>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {children}
      </div>
      <Analytics />
      <ConsentPanel />
    </Wrapper>
  );
};

export default compose(withTheme, connect(mapStateToProps))(App);
