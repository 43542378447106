import { createSelector } from 'reselect';
import { entitiesSelector } from '../App/selectors';
import { getListResults } from '@sportnet/redux-list/ducks';
import at from '@sportnet/utilities/at';
import config from '../../config';

export const listTeamsSelector = createSelector(
  entitiesSelector,
  getListResults(config.ADMIN_TEAMS_LIST_NAME),
  (entities, ids) => at(entities.teams, ids || []),
);
