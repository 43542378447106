import at from '@sportnet/utilities/at';

const serializeParameters = (
  params: { [key: string]: any },
  parameterKeys: string[],
  delimiter = '|',
) => {
  return at(params, parameterKeys).join(delimiter);
};

export default serializeParameters;
