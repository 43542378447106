import * as React from 'react';
import { RootState } from '../../configureStore';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { mb } from '@sportnet/ui/Themes/utilities';
import { rem } from 'polished';
import config from '../../config';
import styled from 'styled-components';

const BrandsWrapper = styled.div`
  background: transparent;
`;

const Wrapper = styled.div``;

const Content = styled.div`
  margin: auto;
  padding: ${rem(16)};
  max-width: ${rem(config.CONTENT_MAX_WIDTH)};
`;

const Brands = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: ${rem(80)};
    width: auto;
  }
  a {
    margin: ${rem(8)} 0;
  }
  ${mb('m')} {
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    a {
      margin: 0;
    }
  }
`;

const CopyrightWrapper = styled.div``;

const Copyright = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div,
  a {
    color: ${({ theme }) => theme.color.primary};
    font-size: ${rem(12)};
  }
`;

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  return {};
};

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const Footer: React.FC<Props> = () => {
  return (
    <Wrapper>
      <BrandsWrapper>
        <Content>
          <Brands>
            <a
              rel="noopener noreferrer"
              href="https://www.thefa.com/"
              target="_blank"
            >
              <div>
                <img alt="thefa.com" src="/FA.svg" />
              </div>
            </a>
            <a
              rel="noopener noreferrer"
              href="http://www.uefa.com"
              target="_blank"
            >
              <div>
                <img alt="uefa.com" src="/uefa.png" />
              </div>
            </a>
            <a
              rel="noopener noreferrer"
              href="http://www.futbalsfz.sk"
              target="_blank"
            >
              <div>
                <img
                  src="https://csm.sportnet.online/images/150x90xresize/csm/d/a/j/dajmespolugol/97/5a/975a374f-f3ab-49b9-abcd-d5a7630f545d.png"
                  alt="futbalsfz.sk"
                />
              </div>
            </a>
          </Brands>
        </Content>
      </BrandsWrapper>
      <CopyrightWrapper>
        <Content>
          <Copyright />
        </Content>
      </CopyrightWrapper>
    </Wrapper>
  );
};

export default compose(connect(mapStateToProps))(Footer);
